import { FC } from "react";

type Props = {
  number: string;
  title: string;
  description: string;
  className?: string;
};

const ServiceBox: FC<Props> = ({
  number,
  title,
  description,
  className,
}: Props) => {
  return (
    <div
      className={`flex flex-col py-[60px] px-10 bg-white rounded-lg w-full ${className}`}
    >
      <h4 className="text-2xl text-dark-500 font-light font-inter">{number}</h4>
      <h3 className="my-3">{title}</h3>
      <div className="text-dark-600 font-inter">{description}</div>
    </div>
  );
};

export default ServiceBox;
