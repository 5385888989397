import { useNavigate } from "react-router-dom";
import Button from "./Button";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };
  return (
    <div
      className="bg-dark-900 flex flex-1 h-screen justify-center items-center"
      id="page-not-found"
    >
      <div className="text-white text-center flex items-center flex-col gap-8">
        <div className="bg-dark-700 rounded-lg font-syne text-2xl w-fit px-4 pt-1 pb-2">
          404
        </div>
        <h1 className="text-6xl font-semibold">Sorry, there’s nothing here.</h1>
        <p>
          This page doesn’t exist or was removed. We suggest going back to home
          page.
        </p>
        <Button className="self-center" onClick={handleBack}>
          Back to home
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
