import Footer from "containers/Footer";
import Header from "containers/Header";
import Routes from "routes/Routes";

function App() {
  return (
    <div>
      <Header />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
