import logo from "images/contrastLogo.svg";
import { Link } from "react-router-dom";

type Props = {
    title: string
}

const JobHeader = ({ title }: Props) => {
    return (
        <div className="flex flex-col bg-shamrock-50">
            <header
                className='py-5 px-4 xl:px-10 flex justify-between z-20'
            >
                <Link to="/" className="flex-shrink-0">
                    <img
                        src={logo}
                        alt="logo of Deftly"
                        className={`flex-shrink-0 cursor-pointer h-10`}
                    />
                </Link>
                <Link to="/" className="font-semibold flex items-center text-gray-800 cursor-pointer">
                    Home
                </Link>
            </header>
            <p className="text-gray-400 text-center font-semibold uppercase mb-1">Open job position</p>
            <h1 className="text-center text-6xl mb-14 text-shamrock-800">{title}</h1>
        </div>
    )
}

export default JobHeader