import JobHeader from "./JobHeader"

type Props = {
    children: React.ReactNode
    title: string
}

const JobLayout = ({ children, title }: Props) => {
    return (
        <>
            <JobHeader title={title} />
            <div className="flex flex-col">
                {children}
            </div>
        </>
    )
}

export default JobLayout