
var Rive = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(Rive) {
  Rive = Rive || {};


null;var k;k||(k=typeof Rive !== 'undefined' ? Rive : {});var aa,ba;k.ready=new Promise(function(a,b){aa=a;ba=b});
k.onRuntimeInitialized=function(){function a(g){this.xd=g;this.be=g.getContext("2d");this.de=c;this.clear=function(){const h=this.xd,m=c.xd;if(m.width<h.width||m.height<h.height)m.width=h.width,m.height=h.height;c.clear()};this.save=function(){c.save()};this.restore=function(){c.restore()};this.transform=function(h){c.transform(h)};this.align=function(h,m,p,q){c.align(h,m,p,q)};this.computeAlignment=function(h,m,p,q,v){c.Ee(h,m,p,q,v)};this.flush=function(){c.flush();const h=this.be;h.globalCompositeOperation=
"copy";h.drawImage(c.xd,0,0)}}function b(g){var h={alpha:1,depth:1,stencil:8,antialias:0,premultipliedAlpha:1,preserveDrawingBuffer:0,preferLowPowerToHighPerformance:0,failIfMajorPerformanceCaveat:0,enableExtensionsByDefault:1,explicitSwapControl:0,renderViaOffscreenBackBuffer:0},m=g.getContext("webgl2",h);m||(m=g.getContext("webgl",h));var p=m;m=ca(da);var q={Ge:m,attributes:h,version:h.He,Ld:p};p.canvas&&(p.canvas.De=q);da[m]=q;("undefined"===typeof h.ie||h.ie)&&ea(q);l=da[m];k.fe=r=l&&l.Ld;h=d(g.width,
g.height);h.ce=m;h.xd=g;h.Td=g.width;h.Sd=g.height;return h}let c=null;const d=k.makeRenderer;k.makeRenderer=function(g,h){return h?(c||(c=b(document.createElement("canvas"))),new a(g)):b(g)};const e=k.Artboard.prototype.draw;k.Artboard.prototype.draw=function(g){e.call(this,g.de||g)};const f=k.WebGLRenderer.prototype.clear;k.WebGLRenderer.prototype.clear=function(){l=da[this.ce];k.fe=r=l&&l.Ld;const g=this.xd;if(this.Td!=g.width||this.Sd!=g.height)this.resize(g.width,g.height),this.Td=g.width,this.Sd=
g.height;f.call(this)}};var fa={},u;for(u in k)k.hasOwnProperty(u)&&(fa[u]=k[u]);var ha="./this.program",ia="object"===typeof window,ja="function"===typeof importScripts,ka="object"===typeof process&&"object"===typeof process.versions&&"string"===typeof process.versions.node,w="",la,ma,na,oa,pa;
if(ka)w=ja?require("path").dirname(w)+"/":__dirname+"/",la=function(a,b){oa||(oa=require("fs"));pa||(pa=require("path"));a=pa.normalize(a);return oa.readFileSync(a,b?null:"utf8")},na=function(a){a=la(a,!0);a.buffer||(a=new Uint8Array(a));a.buffer||qa("Assertion failed: undefined");return a},ma=function(a,b,c){oa||(oa=require("fs"));pa||(pa=require("path"));a=pa.normalize(a);oa.readFile(a,function(d,e){d?c(d):b(e.buffer)})},1<process.argv.length&&(ha=process.argv[1].replace(/\\/g,"/")),process.argv.slice(2),
process.on("uncaughtException",function(a){throw a;}),process.on("unhandledRejection",function(a){throw a;}),k.inspect=function(){return"[Emscripten Module object]"};else if(ia||ja)ja?w=self.location.href:"undefined"!==typeof document&&document.currentScript&&(w=document.currentScript.src),_scriptDir&&(w=_scriptDir),0!==w.indexOf("blob:")?w=w.substr(0,w.replace(/[?#].*/,"").lastIndexOf("/")+1):w="",la=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},ja&&
(na=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),ma=function(a,b,c){var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType="arraybuffer";d.onload=function(){200==d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)};var ra=k.print||console.log.bind(console),x=k.printErr||console.warn.bind(console);for(u in fa)fa.hasOwnProperty(u)&&(k[u]=fa[u]);fa=null;k.thisProgram&&(ha=k.thisProgram);
var sa=0,ta;k.wasmBinary&&(ta=k.wasmBinary);var noExitRuntime=k.noExitRuntime||!0;"object"!==typeof WebAssembly&&qa("no native wasm support detected");var ua,va=!1,wa="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function xa(a,b,c){var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.subarray&&wa)return wa.decode(a.subarray(b,c));for(d="";b<c;){var e=a[b++];if(e&128){var f=a[b++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|f);else{var g=a[b++]&63;e=224==(e&240)?(e&15)<<12|f<<6|g:(e&7)<<18|f<<12|g<<6|a[b++]&63;65536>e?d+=String.fromCharCode(e):(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}return d}function ya(a,b){return a?xa(z,a,b):""}
function za(a,b,c,d){if(!(0<d))return 0;var e=c;d=c+d-1;for(var f=0;f<a.length;++f){var g=a.charCodeAt(f);if(55296<=g&&57343>=g){var h=a.charCodeAt(++f);g=65536+((g&1023)<<10)|h&1023}if(127>=g){if(c>=d)break;b[c++]=g}else{if(2047>=g){if(c+1>=d)break;b[c++]=192|g>>6}else{if(65535>=g){if(c+2>=d)break;b[c++]=224|g>>12}else{if(c+3>=d)break;b[c++]=240|g>>18;b[c++]=128|g>>12&63}b[c++]=128|g>>6&63}b[c++]=128|g&63}}b[c]=0;return c-e}
function Aa(a){for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&(d=65536+((d&1023)<<10)|a.charCodeAt(++c)&1023);127>=d?++b:b=2047>=d?b+2:65535>=d?b+3:b+4}return b}var Ba="undefined"!==typeof TextDecoder?new TextDecoder("utf-16le"):void 0;function Ca(a,b){var c=a>>1;for(var d=c+b/2;!(c>=d)&&Da[c];)++c;c<<=1;if(32<c-a&&Ba)return Ba.decode(z.subarray(a,c));c="";for(d=0;!(d>=b/2);++d){var e=B[a+2*d>>1];if(0==e)break;c+=String.fromCharCode(e)}return c}
function Ea(a,b,c){void 0===c&&(c=2147483647);if(2>c)return 0;c-=2;var d=b;c=c<2*a.length?c/2:a.length;for(var e=0;e<c;++e)B[b>>1]=a.charCodeAt(e),b+=2;B[b>>1]=0;return b-d}function Fa(a){return 2*a.length}function Ga(a,b){for(var c=0,d="";!(c>=b/4);){var e=C[a+4*c>>2];if(0==e)break;++c;65536<=e?(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023)):d+=String.fromCharCode(e)}return d}
function Ha(a,b,c){void 0===c&&(c=2147483647);if(4>c)return 0;var d=b;c=d+c-4;for(var e=0;e<a.length;++e){var f=a.charCodeAt(e);if(55296<=f&&57343>=f){var g=a.charCodeAt(++e);f=65536+((f&1023)<<10)|g&1023}C[b>>2]=f;b+=4;if(b+4>c)break}C[b>>2]=0;return b-d}function Ia(a){for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&++c;b+=4}return b}var Ja,Ka,z,B,Da,C,D,F,La;
function Na(){var a=ua.buffer;Ja=a;k.HEAP8=Ka=new Int8Array(a);k.HEAP16=B=new Int16Array(a);k.HEAP32=C=new Int32Array(a);k.HEAPU8=z=new Uint8Array(a);k.HEAPU16=Da=new Uint16Array(a);k.HEAPU32=D=new Uint32Array(a);k.HEAPF32=F=new Float32Array(a);k.HEAPF64=La=new Float64Array(a)}var Oa,Pa=[],Qa=[],Ra=[];function Sa(){var a=k.preRun.shift();Pa.unshift(a)}var Ta=0,Ua=null,Va=null;k.preloadedImages={};k.preloadedAudios={};
function qa(a){if(k.onAbort)k.onAbort(a);a="Aborted("+a+")";x(a);va=!0;a=new WebAssembly.RuntimeError(a+". Build with -s ASSERTIONS=1 for more info.");ba(a);throw a;}function Wa(){return G.startsWith("data:application/octet-stream;base64,")}var G;G="webgl_advanced.wasm";if(!Wa()){var Xa=G;G=k.locateFile?k.locateFile(Xa,w):w+Xa}function Ya(){var a=G;try{if(a==G&&ta)return new Uint8Array(ta);if(na)return na(a);throw"both async and sync fetching of the wasm failed";}catch(b){qa(b)}}
function Za(){if(!ta&&(ia||ja)){if("function"===typeof fetch&&!G.startsWith("file://"))return fetch(G,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+G+"'";return a.arrayBuffer()}).catch(function(){return Ya()});if(ma)return new Promise(function(a,b){ma(G,function(c){a(new Uint8Array(c))},b)})}return Promise.resolve().then(function(){return Ya()})}
function $a(a){for(;0<a.length;){var b=a.shift();if("function"==typeof b)b(k);else{var c=b.Fe;"number"===typeof c?void 0===b.Md?H(c)():H(c)(b.Md):c(void 0===b.Md?null:b.Md)}}}function H(a){return Oa.get(a)}var ab={};function bb(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function cb(a){return this.fromWireType(D[a>>2])}var db={},eb={},fb={};function gb(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?"_"+a:a}
function hb(a,b){a=gb(a);return(new Function("body","return function "+a+'() {\n    "use strict";    return body.apply(this, arguments);\n};\n'))(b)}function ib(a){var b=Error,c=hb(a,function(d){this.name=a;this.message=d;d=Error(d).stack;void 0!==d&&(this.stack=this.toString()+"\n"+d.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(b.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return c}
var jb=void 0;function kb(a){throw new jb(a);}function I(a,b,c){function d(h){h=c(h);h.length!==a.length&&kb("Mismatched type converter count");for(var m=0;m<a.length;++m)J(a[m],h[m])}a.forEach(function(h){fb[h]=b});var e=Array(b.length),f=[],g=0;b.forEach(function(h,m){eb.hasOwnProperty(h)?e[m]=eb[h]:(f.push(h),db.hasOwnProperty(h)||(db[h]=[]),db[h].push(function(){e[m]=eb[h];++g;g===f.length&&d(e)}))});0===f.length&&d(e)}
function lb(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}var mb=void 0;function K(a){for(var b="";z[a];)b+=mb[z[a++]];return b}var nb=void 0;function L(a){throw new nb(a);}
function J(a,b,c){c=c||{};if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");var d=b.name;a||L('type "'+d+'" must have a positive integer typeid pointer');if(eb.hasOwnProperty(a)){if(c.pe)return;L("Cannot register type '"+d+"' twice")}eb[a]=b;delete fb[a];db.hasOwnProperty(a)&&(b=db[a],delete db[a],b.forEach(function(e){e()}))}function ob(a){L(a.gd.kd.hd.name+" instance already deleted")}var pb=!1;function qb(){}
function rb(a){--a.count.value;0===a.count.value&&(a.nd?a.pd.rd(a.nd):a.kd.hd.rd(a.jd))}function sb(a){if("undefined"===typeof FinalizationGroup)return sb=function(b){return b},a;pb=new FinalizationGroup(function(b){for(var c=b.next();!c.done;c=b.next())c=c.value,c.jd?rb(c):console.warn("object already deleted: "+c.jd)});sb=function(b){pb.register(b,b.gd,b.gd);return b};qb=function(b){pb.unregister(b.gd)};return sb(a)}var tb=void 0,ub=[];
function vb(){for(;ub.length;){var a=ub.pop();a.gd.vd=!1;a["delete"]()}}function wb(){}var xb={};function yb(a,b,c){if(void 0===a[b].ld){var d=a[b];a[b]=function(){a[b].ld.hasOwnProperty(arguments.length)||L("Function '"+c+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[b].ld+")!");return a[b].ld[arguments.length].apply(this,arguments)};a[b].ld=[];a[b].ld[d.Cd]=d}}
function zb(a,b,c){k.hasOwnProperty(a)?((void 0===c||void 0!==k[a].ld&&void 0!==k[a].ld[c])&&L("Cannot register public name '"+a+"' twice"),yb(k,a,a),k.hasOwnProperty(c)&&L("Cannot register multiple overloads of a function with the same number of arguments ("+c+")!"),k[a].ld[c]=b):(k[a]=b,void 0!==c&&(k[a].Je=c))}function Ab(a,b,c,d,e,f,g,h){this.name=a;this.constructor=b;this.sd=c;this.rd=d;this.qd=e;this.ke=f;this.Bd=g;this.he=h;this.te=[]}
function Bb(a,b,c){for(;b!==c;)b.Bd||L("Expected null or instance of "+c.name+", got an instance of "+b.name),a=b.Bd(a),b=b.qd;return a}function Cb(a,b){if(null===b)return this.Od&&L("null is not a valid "+this.name),0;b.gd||L('Cannot pass "'+Db(b)+'" as a '+this.name);b.gd.jd||L("Cannot pass deleted object as a pointer of type "+this.name);return Bb(b.gd.jd,b.gd.kd.hd,this.hd)}
function Eb(a,b){if(null===b){this.Od&&L("null is not a valid "+this.name);if(this.Ed){var c=this.Pd();null!==a&&a.push(this.rd,c);return c}return 0}b.gd||L('Cannot pass "'+Db(b)+'" as a '+this.name);b.gd.jd||L("Cannot pass deleted object as a pointer of type "+this.name);!this.Dd&&b.gd.kd.Dd&&L("Cannot convert argument of type "+(b.gd.pd?b.gd.pd.name:b.gd.kd.name)+" to parameter type "+this.name);c=Bb(b.gd.jd,b.gd.kd.hd,this.hd);if(this.Ed)switch(void 0===b.gd.nd&&L("Passing raw pointer to smart pointer is illegal"),
this.ye){case 0:b.gd.pd===this?c=b.gd.nd:L("Cannot convert argument of type "+(b.gd.pd?b.gd.pd.name:b.gd.kd.name)+" to parameter type "+this.name);break;case 1:c=b.gd.nd;break;case 2:if(b.gd.pd===this)c=b.gd.nd;else{var d=b.clone();c=this.ue(c,Fb(function(){d["delete"]()}));null!==a&&a.push(this.rd,c)}break;default:L("Unsupporting sharing policy")}return c}
function Gb(a,b){if(null===b)return this.Od&&L("null is not a valid "+this.name),0;b.gd||L('Cannot pass "'+Db(b)+'" as a '+this.name);b.gd.jd||L("Cannot pass deleted object as a pointer of type "+this.name);b.gd.kd.Dd&&L("Cannot convert argument of type "+b.gd.kd.name+" to parameter type "+this.name);return Bb(b.gd.jd,b.gd.kd.hd,this.hd)}function Hb(a,b,c){if(b===c)return a;if(void 0===c.qd)return null;a=Hb(a,b,c.qd);return null===a?null:c.he(a)}var Ib={};
function Jb(a,b){for(void 0===b&&L("ptr should not be undefined");a.qd;)b=a.Bd(b),a=a.qd;return Ib[b]}function Kb(a,b){b.kd&&b.jd||kb("makeClassHandle requires ptr and ptrType");!!b.pd!==!!b.nd&&kb("Both smartPtrType and smartPtr must be specified");b.count={value:1};return sb(Object.create(a,{gd:{value:b}}))}
function M(a,b,c,d){this.name=a;this.hd=b;this.Od=c;this.Dd=d;this.Ed=!1;this.rd=this.ue=this.Pd=this.Zd=this.ye=this.se=void 0;void 0!==b.qd?this.toWireType=Eb:(this.toWireType=d?Cb:Gb,this.od=null)}function Lb(a,b,c){k.hasOwnProperty(a)||kb("Replacing nonexistant public symbol");void 0!==k[a].ld&&void 0!==c?k[a].ld[c]=b:(k[a]=b,k[a].Cd=c)}
function Mb(a,b){var c=[];return function(){c.length=arguments.length;for(var d=0;d<arguments.length;d++)c[d]=arguments[d];a.includes("j")?(d=k["dynCall_"+a],d=c&&c.length?d.apply(null,[b].concat(c)):d.call(null,b)):d=H(b).apply(null,c);return d}}function N(a,b){a=K(a);var c=a.includes("j")?Mb(a,b):H(b);"function"!==typeof c&&L("unknown function pointer with signature "+a+": "+b);return c}var Nb=void 0;function Ob(a){a=Pb(a);var b=K(a);O(a);return b}
function Qb(a,b){function c(f){e[f]||eb[f]||(fb[f]?fb[f].forEach(c):(d.push(f),e[f]=!0))}var d=[],e={};b.forEach(c);throw new Nb(a+": "+d.map(Ob).join([", "]));}function Rb(a,b,c){a instanceof Object||L(c+' with invalid "this": '+a);a instanceof b.hd.constructor||L(c+' incompatible with "this" of type '+a.constructor.name);a.gd.jd||L("cannot call emscripten binding method "+c+" on deleted object");return Bb(a.gd.jd,a.gd.kd.hd,b.hd)}
function Sb(a,b){for(var c=[],d=0;d<a;d++)c.push(C[(b>>2)+d]);return c}function Tb(a){var b=Function;if(!(b instanceof Function))throw new TypeError("new_ called with constructor type "+typeof b+" which is not a function");var c=hb(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}
function Ub(a,b,c,d,e){var f=b.length;2>f&&L("argTypes array size mismatch! Must at least get return value and 'this' types!");var g=null!==b[1]&&null!==c,h=!1;for(c=1;c<b.length;++c)if(null!==b[c]&&void 0===b[c].od){h=!0;break}var m="void"!==b[0].name,p="",q="";for(c=0;c<f-2;++c)p+=(0!==c?", ":"")+"arg"+c,q+=(0!==c?", ":"")+"arg"+c+"Wired";a="return function "+gb(a)+"("+p+") {\nif (arguments.length !== "+(f-2)+") {\nthrowBindingError('function "+a+" called with ' + arguments.length + ' arguments, expected "+
(f-2)+" args!');\n}\n";h&&(a+="var destructors = [];\n");var v=h?"destructors":"null";p="throwBindingError invoker fn runDestructors retType classParam".split(" ");d=[L,d,e,bb,b[0],b[1]];g&&(a+="var thisWired = classParam.toWireType("+v+", this);\n");for(c=0;c<f-2;++c)a+="var arg"+c+"Wired = argType"+c+".toWireType("+v+", arg"+c+"); // "+b[c+2].name+"\n",p.push("argType"+c),d.push(b[c+2]);g&&(q="thisWired"+(0<q.length?", ":"")+q);a+=(m?"var rv = ":"")+"invoker(fn"+(0<q.length?", ":"")+q+");\n";if(h)a+=
"runDestructors(destructors);\n";else for(c=g?1:2;c<b.length;++c)f=1===c?"thisWired":"arg"+(c-2)+"Wired",null!==b[c].od&&(a+=f+"_dtor("+f+"); // "+b[c].name+"\n",p.push(f+"_dtor"),d.push(b[c].od));m&&(a+="var ret = retType.fromWireType(rv);\nreturn ret;\n");p.push(a+"}\n");return Tb(p).apply(null,d)}var Vb=[],Q=[{},{value:void 0},{value:null},{value:!0},{value:!1}];function Wb(a){4<a&&0===--Q[a].Qd&&(Q[a]=void 0,Vb.push(a))}
function Xb(a){a||L("Cannot use deleted val. handle = "+a);return Q[a].value}function Fb(a){switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var b=Vb.length?Vb.pop():Q.length;Q[b]={Qd:1,value:a};return b}}
function Yb(a,b,c){switch(b){case 0:return function(d){return this.fromWireType((c?Ka:z)[d])};case 1:return function(d){return this.fromWireType((c?B:Da)[d>>1])};case 2:return function(d){return this.fromWireType((c?C:D)[d>>2])};default:throw new TypeError("Unknown integer type: "+a);}}function Zb(a,b){var c=eb[a];void 0===c&&L(b+" has unknown type "+Ob(a));return c}function Db(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}
function $b(a,b){switch(b){case 2:return function(c){return this.fromWireType(F[c>>2])};case 3:return function(c){return this.fromWireType(La[c>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function ac(a,b,c){switch(b){case 0:return c?function(d){return Ka[d]}:function(d){return z[d]};case 1:return c?function(d){return B[d>>1]}:function(d){return Da[d>>1]};case 2:return c?function(d){return C[d>>2]}:function(d){return D[d>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var bc={};function cc(a){var b=bc[a];return void 0===b?K(a):b}var dc=[];function ec(a){var b=dc.length;dc.push(a);return b}
function fc(a,b){for(var c=Array(a),d=0;d<a;++d)c[d]=Zb(C[(b>>2)+d],"parameter "+d);return c}var gc=[],hc;hc=ka?function(){var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:function(){return performance.now()};
function ic(a){var b=a.getExtension("ANGLE_instanced_arrays");b&&(a.vertexAttribDivisor=function(c,d){b.vertexAttribDivisorANGLE(c,d)},a.drawArraysInstanced=function(c,d,e,f){b.drawArraysInstancedANGLE(c,d,e,f)},a.drawElementsInstanced=function(c,d,e,f,g){b.drawElementsInstancedANGLE(c,d,e,f,g)})}
function jc(a){var b=a.getExtension("OES_vertex_array_object");b&&(a.createVertexArray=function(){return b.createVertexArrayOES()},a.deleteVertexArray=function(c){b.deleteVertexArrayOES(c)},a.bindVertexArray=function(c){b.bindVertexArrayOES(c)},a.isVertexArray=function(c){return b.isVertexArrayOES(c)})}function kc(a){var b=a.getExtension("WEBGL_draw_buffers");b&&(a.drawBuffers=function(c,d){b.drawBuffersWEBGL(c,d)})}
var lc=1,mc=[],R=[],nc=[],oc=[],pc=[],S=[],qc=[],da=[],rc=[],sc=[],tc={},vc={},wc=4;function U(a){xc||(xc=a)}function ca(a){for(var b=lc++,c=a.length;c<b;c++)a[c]=null;return b}
function ea(a){a||(a=l);if(!a.qe){a.qe=!0;var b=a.Ld;ic(b);jc(b);kc(b);b.Vd=b.getExtension("WEBGL_draw_instanced_base_vertex_base_instance");b.Yd=b.getExtension("WEBGL_multi_draw_instanced_base_vertex_base_instance");2<=a.version&&(b.Wd=b.getExtension("EXT_disjoint_timer_query_webgl2"));if(2>a.version||!b.Wd)b.Wd=b.getExtension("EXT_disjoint_timer_query");b.Ie=b.getExtension("WEBGL_multi_draw");(b.getSupportedExtensions()||[]).forEach(function(c){c.includes("lose_context")||c.includes("debug")||b.getExtension(c)})}}
var xc,l,yc=[];function zc(a,b,c,d){for(var e=0;e<a;e++){var f=r[c](),g=f&&ca(d);f?(f.name=g,d[g]=f):U(1282);C[b+4*e>>2]=g}}
function Ac(a,b){if(b){var c=void 0;switch(a){case 36346:c=1;break;case 36344:return;case 34814:case 36345:c=0;break;case 34466:var d=r.getParameter(34467);c=d?d.length:0;break;case 33309:if(2>l.version){U(1282);return}c=2*(r.getSupportedExtensions()||[]).length;break;case 33307:case 33308:if(2>l.version){U(1280);return}c=33307==a?3:0}if(void 0===c)switch(d=r.getParameter(a),typeof d){case "number":c=d;break;case "boolean":c=d?1:0;break;case "string":U(1280);return;case "object":if(null===d)switch(a){case 34964:case 35725:case 34965:case 36006:case 36007:case 32873:case 34229:case 36662:case 36663:case 35053:case 35055:case 36010:case 35097:case 35869:case 32874:case 36389:case 35983:case 35368:case 34068:c=
0;break;default:U(1280);return}else{if(d instanceof Float32Array||d instanceof Uint32Array||d instanceof Int32Array||d instanceof Array){for(a=0;a<d.length;++a)C[b+4*a>>2]=d[a];return}try{c=d.name|0}catch(e){U(1280);x("GL_INVALID_ENUM in glGet0v: Unknown object returned from WebGL getParameter("+a+")! (error: "+e+")");return}}break;default:U(1280);x("GL_INVALID_ENUM in glGet0v: Native code calling glGet0v("+a+") and it returns "+d+" of type "+typeof d+"!");return}C[b>>2]=c}else U(1281)}
function Bc(a){var b=Aa(a)+1,c=Cc(b);za(a,z,c,b);return c}function Dc(a){return"]"==a.slice(-1)&&a.lastIndexOf("[")}function Ec(a){a-=5120;return 0==a?Ka:1==a?z:2==a?B:4==a?C:6==a?F:5==a||28922==a||28520==a||30779==a||30782==a?D:Da}function Fc(a,b,c,d,e){a=Ec(a);var f=31-Math.clz32(a.BYTES_PER_ELEMENT),g=wc;return a.subarray(e>>f,e+d*(c*({5:3,6:4,8:2,29502:3,29504:4,26917:2,26918:2,29846:3,29847:4}[b-6402]||1)*(1<<f)+g-1&-g)>>f)}
function V(a){var b=r.ge;if(b){var c=b.Ad[a];"number"===typeof c&&(b.Ad[a]=c=r.getUniformLocation(b,b.$d[a]+(0<c?"["+c+"]":"")));return c}U(1282)}var Gc=[],Hc=[],Ic={};
function Jc(){if(!Kc){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"===typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ha||"./this.program"},b;for(b in Ic)void 0===Ic[b]?delete a[b]:a[b]=Ic[b];var c=[];for(b in a)c.push(b+"="+a[b]);Kc=c}return Kc}var Kc,Lc=[null,[],[]];function Mc(a){return 0===a%4&&(0!==a%100||0===a%400)}function Nc(a,b){for(var c=0,d=0;d<=b;c+=a[d++]);return c}
var Oc=[31,29,31,30,31,30,31,31,30,31,30,31],Pc=[31,28,31,30,31,30,31,31,30,31,30,31];function Qc(a,b){for(a=new Date(a.getTime());0<b;){var c=a.getMonth(),d=(Mc(a.getFullYear())?Oc:Pc)[c];if(b>d-a.getDate())b-=d-a.getDate()+1,a.setDate(1),11>c?a.setMonth(c+1):(a.setMonth(0),a.setFullYear(a.getFullYear()+1));else{a.setDate(a.getDate()+b);break}}return a}
function Rc(a,b,c,d){function e(n,t,y){for(n="number"===typeof n?n.toString():n||"";n.length<t;)n=y[0]+n;return n}function f(n,t){return e(n,t,"0")}function g(n,t){function y(P){return 0>P?-1:0<P?1:0}var E;0===(E=y(n.getFullYear()-t.getFullYear()))&&0===(E=y(n.getMonth()-t.getMonth()))&&(E=y(n.getDate()-t.getDate()));return E}function h(n){switch(n.getDay()){case 0:return new Date(n.getFullYear()-1,11,29);case 1:return n;case 2:return new Date(n.getFullYear(),0,3);case 3:return new Date(n.getFullYear(),
0,2);case 4:return new Date(n.getFullYear(),0,1);case 5:return new Date(n.getFullYear()-1,11,31);case 6:return new Date(n.getFullYear()-1,11,30)}}function m(n){n=Qc(new Date(n.md+1900,0,1),n.Kd);var t=new Date(n.getFullYear()+1,0,4),y=h(new Date(n.getFullYear(),0,4));t=h(t);return 0>=g(y,n)?0>=g(t,n)?n.getFullYear()+1:n.getFullYear():n.getFullYear()-1}var p=C[d+40>>2];d={Be:C[d>>2],Ae:C[d+4>>2],Id:C[d+8>>2],zd:C[d+12>>2],wd:C[d+16>>2],md:C[d+20>>2],Jd:C[d+24>>2],Kd:C[d+28>>2],Ke:C[d+32>>2],ze:C[d+
36>>2],Ce:p?ya(p):""};c=ya(c);p={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var q in p)c=c.replace(new RegExp(q,"g"),p[q]);var v="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
A="January February March April May June July August September October November December".split(" ");p={"%a":function(n){return v[n.Jd].substring(0,3)},"%A":function(n){return v[n.Jd]},"%b":function(n){return A[n.wd].substring(0,3)},"%B":function(n){return A[n.wd]},"%C":function(n){return f((n.md+1900)/100|0,2)},"%d":function(n){return f(n.zd,2)},"%e":function(n){return e(n.zd,2," ")},"%g":function(n){return m(n).toString().substring(2)},"%G":function(n){return m(n)},"%H":function(n){return f(n.Id,
2)},"%I":function(n){n=n.Id;0==n?n=12:12<n&&(n-=12);return f(n,2)},"%j":function(n){return f(n.zd+Nc(Mc(n.md+1900)?Oc:Pc,n.wd-1),3)},"%m":function(n){return f(n.wd+1,2)},"%M":function(n){return f(n.Ae,2)},"%n":function(){return"\n"},"%p":function(n){return 0<=n.Id&&12>n.Id?"AM":"PM"},"%S":function(n){return f(n.Be,2)},"%t":function(){return"\t"},"%u":function(n){return n.Jd||7},"%U":function(n){var t=new Date(n.md+1900,0,1),y=0===t.getDay()?t:Qc(t,7-t.getDay());n=new Date(n.md+1900,n.wd,n.zd);return 0>
g(y,n)?f(Math.ceil((31-y.getDate()+(Nc(Mc(n.getFullYear())?Oc:Pc,n.getMonth()-1)-31)+n.getDate())/7),2):0===g(y,t)?"01":"00"},"%V":function(n){var t=new Date(n.md+1901,0,4),y=h(new Date(n.md+1900,0,4));t=h(t);var E=Qc(new Date(n.md+1900,0,1),n.Kd);return 0>g(E,y)?"53":0>=g(t,E)?"01":f(Math.ceil((y.getFullYear()<n.md+1900?n.Kd+32-y.getDate():n.Kd+1-y.getDate())/7),2)},"%w":function(n){return n.Jd},"%W":function(n){var t=new Date(n.md,0,1),y=1===t.getDay()?t:Qc(t,0===t.getDay()?1:7-t.getDay()+1);n=
new Date(n.md+1900,n.wd,n.zd);return 0>g(y,n)?f(Math.ceil((31-y.getDate()+(Nc(Mc(n.getFullYear())?Oc:Pc,n.getMonth()-1)-31)+n.getDate())/7),2):0===g(y,t)?"01":"00"},"%y":function(n){return(n.md+1900).toString().substring(2)},"%Y":function(n){return n.md+1900},"%z":function(n){n=n.ze;var t=0<=n;n=Math.abs(n)/60;return(t?"+":"-")+String("0000"+(n/60*100+n%60)).slice(-4)},"%Z":function(n){return n.Ce},"%%":function(){return"%"}};for(q in p)c.includes(q)&&(c=c.replace(new RegExp(q,"g"),p[q](d)));q=Sc(c);
if(q.length>b)return 0;Ka.set(q,a);return q.length-1}jb=k.InternalError=ib("InternalError");for(var Tc=Array(256),Uc=0;256>Uc;++Uc)Tc[Uc]=String.fromCharCode(Uc);mb=Tc;nb=k.BindingError=ib("BindingError");wb.prototype.isAliasOf=function(a){if(!(this instanceof wb&&a instanceof wb))return!1;var b=this.gd.kd.hd,c=this.gd.jd,d=a.gd.kd.hd;for(a=a.gd.jd;b.qd;)c=b.Bd(c),b=b.qd;for(;d.qd;)a=d.Bd(a),d=d.qd;return b===d&&c===a};
wb.prototype.clone=function(){this.gd.jd||ob(this);if(this.gd.yd)return this.gd.count.value+=1,this;var a=sb,b=Object,c=b.create,d=Object.getPrototypeOf(this),e=this.gd;a=a(c.call(b,d,{gd:{value:{count:e.count,vd:e.vd,yd:e.yd,jd:e.jd,kd:e.kd,nd:e.nd,pd:e.pd}}}));a.gd.count.value+=1;a.gd.vd=!1;return a};wb.prototype["delete"]=function(){this.gd.jd||ob(this);this.gd.vd&&!this.gd.yd&&L("Object already scheduled for deletion");qb(this);rb(this.gd);this.gd.yd||(this.gd.nd=void 0,this.gd.jd=void 0)};
wb.prototype.isDeleted=function(){return!this.gd.jd};wb.prototype.deleteLater=function(){this.gd.jd||ob(this);this.gd.vd&&!this.gd.yd&&L("Object already scheduled for deletion");ub.push(this);1===ub.length&&tb&&tb(vb);this.gd.vd=!0;return this};M.prototype.le=function(a){this.Zd&&(a=this.Zd(a));return a};M.prototype.Ud=function(a){this.rd&&this.rd(a)};M.prototype.argPackAdvance=8;M.prototype.readValueFromPointer=cb;M.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
M.prototype.fromWireType=function(a){function b(){return this.Ed?Kb(this.hd.sd,{kd:this.se,jd:c,pd:this,nd:a}):Kb(this.hd.sd,{kd:this,jd:a})}var c=this.le(a);if(!c)return this.Ud(a),null;var d=Jb(this.hd,c);if(void 0!==d){if(0===d.gd.count.value)return d.gd.jd=c,d.gd.nd=a,d.clone();d=d.clone();this.Ud(a);return d}d=this.hd.ke(c);d=xb[d];if(!d)return b.call(this);d=this.Dd?d.ee:d.pointerType;var e=Hb(c,this.hd,d.hd);return null===e?b.call(this):this.Ed?Kb(d.hd.sd,{kd:d,jd:e,pd:this,nd:a}):Kb(d.hd.sd,
{kd:d,jd:e})};k.getInheritedInstanceCount=function(){return Object.keys(Ib).length};k.getLiveInheritedInstances=function(){var a=[],b;for(b in Ib)Ib.hasOwnProperty(b)&&a.push(Ib[b]);return a};k.flushPendingDeletes=vb;k.setDelayFunction=function(a){tb=a;ub.length&&tb&&tb(vb)};Nb=k.UnboundTypeError=ib("UnboundTypeError");k.count_emval_handles=function(){for(var a=0,b=5;b<Q.length;++b)void 0!==Q[b]&&++a;return a};k.get_first_emval=function(){for(var a=5;a<Q.length;++a)if(void 0!==Q[a])return Q[a];return null};
for(var r,W=0;32>W;++W)yc.push(Array(W));var Vc=new Float32Array(288);for(W=0;288>W;++W)Gc[W]=Vc.subarray(0,W+1);var Wc=new Int32Array(288);for(W=0;288>W;++W)Hc[W]=Wc.subarray(0,W+1);function Sc(a){var b=Array(Aa(a)+1);za(a,b,0,b.length);return b}
var md={bb:function(a){var b=ab[a];delete ab[a];var c=b.Pd,d=b.rd,e=b.Xd,f=e.map(function(g){return g.oe}).concat(e.map(function(g){return g.we}));I([a],f,function(g){var h={};e.forEach(function(m,p){var q=g[p],v=m.me,A=m.ne,n=g[p+e.length],t=m.ve,y=m.xe;h[m.je]={read:function(E){return q.fromWireType(v(A,E))},write:function(E,P){var T=[];t(y,E,n.toWireType(T,P));bb(T)}}});return[{name:b.name,fromWireType:function(m){var p={},q;for(q in h)p[q]=h[q].read(m);d(m);return p},toWireType:function(m,p){for(var q in h)if(!(q in
p))throw new TypeError('Missing field:  "'+q+'"');var v=c();for(q in h)h[q].write(v,p[q]);null!==m&&m.push(d,v);return v},argPackAdvance:8,readValueFromPointer:cb,od:d}]})},fb:function(){},vb:function(a,b,c,d,e){var f=lb(c);b=K(b);J(a,{name:b,fromWireType:function(g){return!!g},toWireType:function(g,h){return h?d:e},argPackAdvance:8,readValueFromPointer:function(g){if(1===c)var h=Ka;else if(2===c)h=B;else if(4===c)h=C;else throw new TypeError("Unknown boolean type size: "+b);return this.fromWireType(h[g>>
f])},od:null})},h:function(a,b,c,d,e,f,g,h,m,p,q,v,A){q=K(q);f=N(e,f);h&&(h=N(g,h));p&&(p=N(m,p));A=N(v,A);var n=gb(q);zb(n,function(){Qb("Cannot construct "+q+" due to unbound types",[d])});I([a,b,c],d?[d]:[],function(t){t=t[0];if(d){var y=t.hd;var E=y.sd}else E=wb.prototype;t=hb(n,function(){if(Object.getPrototypeOf(this)!==P)throw new nb("Use 'new' to construct "+q);if(void 0===T.td)throw new nb(q+" has no accessible constructor");var uc=T.td[arguments.length];if(void 0===uc)throw new nb("Tried to invoke ctor of "+
q+" with invalid number of parameters ("+arguments.length+") - expected ("+Object.keys(T.td).toString()+") parameters instead!");return uc.apply(this,arguments)});var P=Object.create(E,{constructor:{value:t}});t.prototype=P;var T=new Ab(q,t,P,A,y,f,h,p);y=new M(q,T,!0,!1);E=new M(q+"*",T,!1,!1);var Ma=new M(q+" const*",T,!1,!0);xb[a]={pointerType:E,ee:Ma};Lb(n,t);return[y,E,Ma]})},k:function(a,b,c,d,e,f,g,h){b=K(b);f=N(e,f);I([],[a],function(m){m=m[0];var p=m.name+"."+b,q={get:function(){Qb("Cannot access "+
p+" due to unbound types",[c])},enumerable:!0,configurable:!0};q.set=h?function(){Qb("Cannot access "+p+" due to unbound types",[c])}:function(){L(p+" is a read-only property")};Object.defineProperty(m.hd.constructor,b,q);I([],[c],function(v){v=v[0];var A={get:function(){return v.fromWireType(f(d))},enumerable:!0};h&&(h=N(g,h),A.set=function(n){var t=[];h(d,v.toWireType(t,n));bb(t)});Object.defineProperty(m.hd.constructor,b,A);return[]});return[]})},v:function(a,b,c,d,e,f){0<b||qa("Assertion failed: undefined");
var g=Sb(b,c);e=N(d,e);I([],[a],function(h){h=h[0];var m="constructor "+h.name;void 0===h.hd.td&&(h.hd.td=[]);if(void 0!==h.hd.td[b-1])throw new nb("Cannot register multiple constructors with identical number of parameters ("+(b-1)+") for class '"+h.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");h.hd.td[b-1]=function(){Qb("Cannot construct "+h.name+" due to unbound types",g)};I([],g,function(p){p.splice(1,0,null);h.hd.td[b-1]=Ub(m,p,null,
e,f);return[]});return[]})},e:function(a,b,c,d,e,f,g,h){var m=Sb(c,d);b=K(b);f=N(e,f);I([],[a],function(p){function q(){Qb("Cannot call "+v+" due to unbound types",m)}p=p[0];var v=p.name+"."+b;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);h&&p.hd.te.push(b);var A=p.hd.sd,n=A[b];void 0===n||void 0===n.ld&&n.className!==p.name&&n.Cd===c-2?(q.Cd=c-2,q.className=p.name,A[b]=q):(yb(A,b,v),A[b].ld[c-2]=q);I([],m,function(t){t=Ub(v,t,p,f,g);void 0===A[b].ld?(t.Cd=c-2,A[b]=t):A[b].ld[c-2]=t;return[]});return[]})},
f:function(a,b,c,d,e,f,g,h,m,p){b=K(b);e=N(d,e);I([],[a],function(q){q=q[0];var v=q.name+"."+b,A={get:function(){Qb("Cannot access "+v+" due to unbound types",[c,g])},enumerable:!0,configurable:!0};A.set=m?function(){Qb("Cannot access "+v+" due to unbound types",[c,g])}:function(){L(v+" is a read-only property")};Object.defineProperty(q.hd.sd,b,A);I([],m?[c,g]:[c],function(n){var t=n[0],y={get:function(){var P=Rb(this,q,v+" getter");return t.fromWireType(e(f,P))},enumerable:!0};if(m){m=N(h,m);var E=
n[1];y.set=function(P){var T=Rb(this,q,v+" setter"),Ma=[];m(p,T,E.toWireType(Ma,P));bb(Ma)}}Object.defineProperty(q.hd.sd,b,y);return[]});return[]})},ub:function(a,b){b=K(b);J(a,{name:b,fromWireType:function(c){var d=Xb(c);Wb(c);return d},toWireType:function(c,d){return Fb(d)},argPackAdvance:8,readValueFromPointer:cb,od:null})},mb:function(a,b,c,d){function e(){}c=lb(c);b=K(b);e.values={};J(a,{name:b,constructor:e,fromWireType:function(f){return this.constructor.values[f]},toWireType:function(f,g){return g.value},
argPackAdvance:8,readValueFromPointer:Yb(b,c,d),od:null});zb(b,e)},jb:function(a,b,c){var d=Zb(a,"enum");b=K(b);a=d.constructor;d=Object.create(d.constructor.prototype,{value:{value:c},constructor:{value:hb(d.name+"_"+b,function(){})}});a.values[c]=d;a[b]=d},x:function(a,b,c){c=lb(c);b=K(b);J(a,{name:b,fromWireType:function(d){return d},toWireType:function(d,e){return e},argPackAdvance:8,readValueFromPointer:$b(b,c),od:null})},z:function(a,b,c,d,e,f){var g=Sb(b,c);a=K(a);e=N(d,e);zb(a,function(){Qb("Cannot call "+
a+" due to unbound types",g)},b-1);I([],g,function(h){h=[h[0],null].concat(h.slice(1));Lb(a,Ub(a,h,null,e,f),b-1);return[]})},m:function(a,b,c,d,e){function f(p){return p}b=K(b);-1===e&&(e=4294967295);var g=lb(c);if(0===d){var h=32-8*c;f=function(p){return p<<h>>>h}}var m=b.includes("unsigned");J(a,{name:b,fromWireType:f,toWireType:function(p,q){if("number"!==typeof q&&"boolean"!==typeof q)throw new TypeError('Cannot convert "'+Db(q)+'" to '+this.name);if(q<d||q>e)throw new TypeError('Passing a number "'+
Db(q)+'" from JS side to C/C++ side to an argument of type "'+b+'", which is outside the valid range ['+d+", "+e+"]!");return m?q>>>0:q|0},argPackAdvance:8,readValueFromPointer:ac(b,g,0!==d),od:null})},l:function(a,b,c){function d(f){f>>=2;var g=D;return new e(Ja,g[f+1],g[f])}var e=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];c=K(c);J(a,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{pe:!0})},y:function(a,b){b=K(b);var c="std::string"===
b;J(a,{name:b,fromWireType:function(d){var e=D[d>>2];if(c)for(var f=d+4,g=0;g<=e;++g){var h=d+4+g;if(g==e||0==z[h]){f=ya(f,h-f);if(void 0===m)var m=f;else m+=String.fromCharCode(0),m+=f;f=h+1}}else{m=Array(e);for(g=0;g<e;++g)m[g]=String.fromCharCode(z[d+4+g]);m=m.join("")}O(d);return m},toWireType:function(d,e){e instanceof ArrayBuffer&&(e=new Uint8Array(e));var f="string"===typeof e;f||e instanceof Uint8Array||e instanceof Uint8ClampedArray||e instanceof Int8Array||L("Cannot pass non-string to std::string");
var g=(c&&f?function(){return Aa(e)}:function(){return e.length})(),h=Cc(4+g+1);D[h>>2]=g;if(c&&f)za(e,z,h+4,g+1);else if(f)for(f=0;f<g;++f){var m=e.charCodeAt(f);255<m&&(O(h),L("String has UTF-16 code units that do not fit in 8 bits"));z[h+4+f]=m}else for(f=0;f<g;++f)z[h+4+f]=e[f];null!==d&&d.push(O,h);return h},argPackAdvance:8,readValueFromPointer:cb,od:function(d){O(d)}})},u:function(a,b,c){c=K(c);if(2===b){var d=Ca;var e=Ea;var f=Fa;var g=function(){return Da};var h=1}else 4===b&&(d=Ga,e=Ha,
f=Ia,g=function(){return D},h=2);J(a,{name:c,fromWireType:function(m){for(var p=D[m>>2],q=g(),v,A=m+4,n=0;n<=p;++n){var t=m+4+n*b;if(n==p||0==q[t>>h])A=d(A,t-A),void 0===v?v=A:(v+=String.fromCharCode(0),v+=A),A=t+b}O(m);return v},toWireType:function(m,p){"string"!==typeof p&&L("Cannot pass non-string to C++ string type "+c);var q=f(p),v=Cc(4+q+b);D[v>>2]=q>>h;e(p,v+4,q+b);null!==m&&m.push(O,v);return v},argPackAdvance:8,readValueFromPointer:cb,od:function(m){O(m)}})},cb:function(a,b,c,d,e,f){ab[a]=
{name:K(b),Pd:N(c,d),rd:N(e,f),Xd:[]}},ab:function(a,b,c,d,e,f,g,h,m,p){ab[a].Xd.push({je:K(b),oe:c,me:N(d,e),ne:f,we:g,ve:N(h,m),xe:p})},wb:function(a,b){b=K(b);J(a,{re:!0,name:b,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},nb:function(){throw"longjmp";},pa:function(a,b,c){a=Xb(a);b=Zb(b,"emval::as");var d=[],e=Fb(d);C[c>>2]=e;return b.toWireType(d,a)},K:function(a,b,c,d){a=dc[a];b=Xb(b);c=cc(c);a(b,c,null,d)},Gc:Wb,Fc:function(a,b){var c=fc(a,b),d=c[0];b=d.name+"_$"+c.slice(1).map(function(q){return q.name}).join("_")+
"$";var e=gc[b];if(void 0!==e)return e;e=["retType"];for(var f=[d],g="",h=0;h<a-1;++h)g+=(0!==h?", ":"")+"arg"+h,e.push("argType"+h),f.push(c[1+h]);var m="return function "+gb("methodCaller_"+b)+"(handle, name, destructors, args) {\n",p=0;for(h=0;h<a-1;++h)m+="    var arg"+h+" = argType"+h+".readValueFromPointer(args"+(p?"+"+p:"")+");\n",p+=c[h+1].argPackAdvance;m+="    var rv = handle[name]("+g+");\n";for(h=0;h<a-1;++h)c[h+1].deleteObject&&(m+="    argType"+h+".deleteObject(arg"+h+");\n");d.re||
(m+="    return retType.toWireType(destructors, rv);\n");e.push(m+"};\n");a=Tb(e).apply(null,f);e=ec(a);return gc[b]=e},La:function(a,b){a=Xb(a);b=Xb(b);return Fb(a[b])},uc:function(a){4<a&&(Q[a].Qd+=1)},Aa:function(a){return Fb(cc(a))},ea:function(a){var b=Xb(a);bb(b);Wb(a)},V:function(a,b){a=Zb(a,"_emval_take_value");a=a.readValueFromPointer(b);return Fb(a)},a:function(){qa("")},ob:function(a,b){if(0===a)a=Date.now();else if(1===a||4===a)a=hc();else return C[Xc()>>2]=28,-1;C[b>>2]=a/1E3|0;C[b+4>>
2]=a%1E3*1E6|0;return 0},Xa:function(a){r.activeTexture(a)},Wa:function(a,b){r.attachShader(R[a],S[b])},Va:function(a,b,c){r.bindAttribLocation(R[a],b,ya(c))},Ua:function(a,b){35051==a?r.Nd=b:35052==a&&(r.ud=b);r.bindBuffer(a,mc[b])},Wb:function(a,b){r.bindFramebuffer(a,nc[b])},Vb:function(a,b){r.bindRenderbuffer(a,oc[b])},Eb:function(a,b){r.bindSampler(a,rc[b])},Ta:function(a,b){r.bindTexture(a,pc[b])},mc:function(a){r.bindVertexArray(qc[a])},jc:function(a){r.bindVertexArray(qc[a])},Sa:function(a,
b,c,d){r.blendColor(a,b,c,d)},Ra:function(a){r.blendEquation(a)},Qa:function(a,b){r.blendFunc(a,b)},Jb:function(a,b,c,d,e,f,g,h,m,p){r.blitFramebuffer(a,b,c,d,e,f,g,h,m,p)},Pa:function(a,b,c,d){2<=l.version?c?r.bufferData(a,z,d,c,b):r.bufferData(a,b,d):r.bufferData(a,c?z.subarray(c,c+b):b,d)},Oa:function(a,b,c,d){2<=l.version?r.bufferSubData(a,b,z,d,c):r.bufferSubData(a,b,z.subarray(d,d+c))},Ub:function(a){return r.checkFramebufferStatus(a)},Na:function(a){r.clear(a)},Ma:function(a,b,c,d){r.clearColor(a,
b,c,d)},Ka:function(a){r.clearStencil(a)},_a:function(a,b,c,d){return r.clientWaitSync(sc[a],b,(c>>>0)+4294967296*d)},Ja:function(a,b,c,d){r.colorMask(!!a,!!b,!!c,!!d)},Ia:function(a){r.compileShader(S[a])},Ha:function(a,b,c,d,e,f,g,h){2<=l.version?r.ud?r.compressedTexImage2D(a,b,c,d,e,f,g,h):r.compressedTexImage2D(a,b,c,d,e,f,z,h,g):r.compressedTexImage2D(a,b,c,d,e,f,h?z.subarray(h,h+g):null)},Ga:function(a,b,c,d,e,f,g,h,m){2<=l.version?r.ud?r.compressedTexSubImage2D(a,b,c,d,e,f,g,h,m):r.compressedTexSubImage2D(a,
b,c,d,e,f,g,z,m,h):r.compressedTexSubImage2D(a,b,c,d,e,f,g,m?z.subarray(m,m+h):null)},Fa:function(a,b,c,d,e,f,g,h){r.copyTexSubImage2D(a,b,c,d,e,f,g,h)},Ea:function(){var a=ca(R),b=r.createProgram();b.name=a;b.Hd=b.Fd=b.Gd=0;b.Rd=1;R[a]=b;return a},Da:function(a){var b=ca(S);S[b]=r.createShader(a);return b},Ca:function(a){r.cullFace(a)},Ba:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2],e=mc[d];e&&(r.deleteBuffer(e),e.name=0,mc[d]=null,d==r.Nd&&(r.Nd=0),d==r.ud&&(r.ud=0))}},Tb:function(a,b){for(var c=
0;c<a;++c){var d=C[b+4*c>>2],e=nc[d];e&&(r.deleteFramebuffer(e),e.name=0,nc[d]=null)}},za:function(a){if(a){var b=R[a];b?(r.deleteProgram(b),b.name=0,R[a]=null):U(1281)}},Sb:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2],e=oc[d];e&&(r.deleteRenderbuffer(e),e.name=0,oc[d]=null)}},Db:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2],e=rc[d];e&&(r.deleteSampler(e),e.name=0,rc[d]=null)}},ya:function(a){if(a){var b=S[a];b?(r.deleteShader(b),S[a]=null):U(1281)}},Hb:function(a){if(a){var b=sc[a];
b?(r.deleteSync(b),b.name=0,sc[a]=null):U(1281)}},xa:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2],e=pc[d];e&&(r.deleteTexture(e),e.name=0,pc[d]=null)}},lc:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2];r.deleteVertexArray(qc[d]);qc[d]=null}},ic:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2];r.deleteVertexArray(qc[d]);qc[d]=null}},wa:function(a){r.depthMask(!!a)},va:function(a){r.disable(a)},ua:function(a){r.disableVertexAttribArray(a)},ta:function(a,b,c){r.drawArrays(a,b,c)},gc:function(a,
b,c,d){r.drawArraysInstanced(a,b,c,d)},ec:function(a,b,c,d,e){r.Vd.drawArraysInstancedBaseInstanceWEBGL(a,b,c,d,e)},cc:function(a,b){for(var c=yc[a],d=0;d<a;d++)c[d]=C[b+4*d>>2];r.drawBuffers(c)},sa:function(a,b,c,d){r.drawElements(a,b,c,d)},fc:function(a,b,c,d,e){r.drawElementsInstanced(a,b,c,d,e)},dc:function(a,b,c,d,e,f,g){r.Vd.drawElementsInstancedBaseVertexBaseInstanceWEBGL(a,b,c,d,e,f,g)},_b:function(a,b,c,d,e,f){r.drawElements(a,d,e,f)},ra:function(a){r.enable(a)},qa:function(a){r.enableVertexAttribArray(a)},
Gb:function(a,b){return(a=r.fenceSync(a,b))?(b=ca(sc),a.name=b,sc[b]=a,b):0},oa:function(){r.finish()},na:function(){r.flush()},Rb:function(a,b,c,d){r.framebufferRenderbuffer(a,b,c,oc[d])},Qb:function(a,b,c,d,e){r.framebufferTexture2D(a,b,c,pc[d],e)},ma:function(a){r.frontFace(a)},la:function(a,b){zc(a,b,"createBuffer",mc)},Pb:function(a,b){zc(a,b,"createFramebuffer",nc)},Ob:function(a,b){zc(a,b,"createRenderbuffer",oc)},Cb:function(a,b){zc(a,b,"createSampler",rc)},ka:function(a,b){zc(a,b,"createTexture",
pc)},kc:function(a,b){zc(a,b,"createVertexArray",qc)},hc:function(a,b){zc(a,b,"createVertexArray",qc)},Nb:function(a){r.generateMipmap(a)},ja:function(a,b,c){c?C[c>>2]=r.getBufferParameter(a,b):U(1281)},ia:function(){var a=r.getError()||xc;xc=0;return a},Mb:function(a,b,c,d){a=r.getFramebufferAttachmentParameter(a,b,c);if(a instanceof WebGLRenderbuffer||a instanceof WebGLTexture)a=a.name|0;C[d>>2]=a},Ya:function(a,b){Ac(a,b)},ha:function(a,b,c,d){a=r.getProgramInfoLog(R[a]);null===a&&(a="(unknown error)");
b=0<b&&d?za(a,z,d,b):0;c&&(C[c>>2]=b)},ga:function(a,b,c){if(c)if(a>=lc)U(1281);else if(a=R[a],35716==b)a=r.getProgramInfoLog(a),null===a&&(a="(unknown error)"),C[c>>2]=a.length+1;else if(35719==b){if(!a.Hd)for(b=0;b<r.getProgramParameter(a,35718);++b)a.Hd=Math.max(a.Hd,r.getActiveUniform(a,b).name.length+1);C[c>>2]=a.Hd}else if(35722==b){if(!a.Fd)for(b=0;b<r.getProgramParameter(a,35721);++b)a.Fd=Math.max(a.Fd,r.getActiveAttrib(a,b).name.length+1);C[c>>2]=a.Fd}else if(35381==b){if(!a.Gd)for(b=0;b<
r.getProgramParameter(a,35382);++b)a.Gd=Math.max(a.Gd,r.getActiveUniformBlockName(a,b).length+1);C[c>>2]=a.Gd}else C[c>>2]=r.getProgramParameter(a,b);else U(1281)},Lb:function(a,b,c){c?C[c>>2]=r.getRenderbufferParameter(a,b):U(1281)},fa:function(a,b,c,d){a=r.getShaderInfoLog(S[a]);null===a&&(a="(unknown error)");b=0<b&&d?za(a,z,d,b):0;c&&(C[c>>2]=b)},xb:function(a,b,c,d){a=r.getShaderPrecisionFormat(a,b);C[c>>2]=a.rangeMin;C[c+4>>2]=a.rangeMax;C[d>>2]=a.precision},da:function(a,b,c){c?35716==b?(a=
r.getShaderInfoLog(S[a]),null===a&&(a="(unknown error)"),C[c>>2]=a?a.length+1:0):35720==b?(a=r.getShaderSource(S[a]),C[c>>2]=a?a.length+1:0):C[c>>2]=r.getShaderParameter(S[a],b):U(1281)},s:function(a){var b=tc[a];if(!b){switch(a){case 7939:b=r.getSupportedExtensions()||[];b=b.concat(b.map(function(d){return"GL_"+d}));b=Bc(b.join(" "));break;case 7936:case 7937:case 37445:case 37446:(b=r.getParameter(a))||U(1280);b=b&&Bc(b);break;case 7938:b=r.getParameter(7938);b=2<=l.version?"OpenGL ES 3.0 ("+b+
")":"OpenGL ES 2.0 ("+b+")";b=Bc(b);break;case 35724:b=r.getParameter(35724);var c=b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);null!==c&&(3==c[1].length&&(c[1]+="0"),b="OpenGL ES GLSL ES "+c[1]+" ("+b+")");b=Bc(b);break;default:U(1280)}tc[a]=b}return b},Za:function(a,b){if(2>l.version)return U(1282),0;var c=vc[a];if(c)return 0>b||b>=c.length?(U(1281),0):c[b];switch(a){case 7939:return c=r.getSupportedExtensions()||[],c=c.concat(c.map(function(d){return"GL_"+d})),c=c.map(function(d){return Bc(d)}),
c=vc[a]=c,0>b||b>=c.length?(U(1281),0):c[b];default:return U(1280),0}},ca:function(a,b){b=ya(b);if(a=R[a]){var c=a,d=c.Ad,e=c.ae,f;if(!d)for(c.Ad=d={},c.$d={},f=0;f<r.getProgramParameter(c,35718);++f){var g=r.getActiveUniform(c,f);var h=g.name;g=g.size;var m=Dc(h);m=0<m?h.slice(0,m):h;var p=c.Rd;c.Rd+=g;e[m]=[g,p];for(h=0;h<g;++h)d[p]=h,c.$d[p++]=m}c=a.Ad;d=0;e=b;f=Dc(b);0<f&&(d=parseInt(b.slice(f+1))>>>0,e=b.slice(0,f));if((e=a.ae[e])&&d<e[0]&&(d+=e[1],c[d]=c[d]||r.getUniformLocation(a,b)))return d}else U(1281);
return-1},zb:function(a,b,c){for(var d=yc[b],e=0;e<b;e++)d[e]=C[c+4*e>>2];r.invalidateFramebuffer(a,d)},yb:function(a,b,c,d,e,f,g){for(var h=yc[b],m=0;m<b;m++)h[m]=C[c+4*m>>2];r.invalidateSubFramebuffer(a,h,d,e,f,g)},Fb:function(a){return r.isSync(sc[a])},ba:function(a){return(a=pc[a])?r.isTexture(a):0},aa:function(a){r.lineWidth(a)},$:function(a){a=R[a];r.linkProgram(a);a.Ad=0;a.ae={}},ac:function(a,b,c,d,e,f){r.Yd.multiDrawArraysInstancedBaseInstanceWEBGL(a,C,b>>2,C,c>>2,C,d>>2,D,e>>2,f)},$b:function(a,
b,c,d,e,f,g,h){r.Yd.multiDrawElementsInstancedBaseVertexBaseInstanceWEBGL(a,C,b>>2,c,C,d>>2,C,e>>2,C,f>>2,D,g>>2,h)},_:function(a,b){3317==a&&(wc=b);r.pixelStorei(a,b)},bc:function(a){r.readBuffer(a)},Z:function(a,b,c,d,e,f,g){if(2<=l.version)if(r.Nd)r.readPixels(a,b,c,d,e,f,g);else{var h=Ec(f);r.readPixels(a,b,c,d,e,f,h,g>>31-Math.clz32(h.BYTES_PER_ELEMENT))}else(g=Fc(f,e,c,d,g))?r.readPixels(a,b,c,d,e,f,g):U(1280)},Kb:function(a,b,c,d){r.renderbufferStorage(a,b,c,d)},Ib:function(a,b,c,d,e){r.renderbufferStorageMultisample(a,
b,c,d,e)},Bb:function(a,b,c){r.samplerParameteri(rc[a],b,c)},Ab:function(a,b,c){r.samplerParameteri(rc[a],b,C[c>>2])},Y:function(a,b,c,d){r.scissor(a,b,c,d)},X:function(a,b,c,d){for(var e="",f=0;f<b;++f){var g=d?C[d+4*f>>2]:-1;e+=ya(C[c+4*f>>2],0>g?void 0:g)}r.shaderSource(S[a],e)},W:function(a,b,c){r.stencilFunc(a,b,c)},U:function(a,b,c,d){r.stencilFuncSeparate(a,b,c,d)},T:function(a){r.stencilMask(a)},S:function(a,b){r.stencilMaskSeparate(a,b)},R:function(a,b,c){r.stencilOp(a,b,c)},Q:function(a,
b,c,d){r.stencilOpSeparate(a,b,c,d)},P:function(a,b,c,d,e,f,g,h,m){if(2<=l.version)if(r.ud)r.texImage2D(a,b,c,d,e,f,g,h,m);else if(m){var p=Ec(h);r.texImage2D(a,b,c,d,e,f,g,h,p,m>>31-Math.clz32(p.BYTES_PER_ELEMENT))}else r.texImage2D(a,b,c,d,e,f,g,h,null);else r.texImage2D(a,b,c,d,e,f,g,h,m?Fc(h,g,d,e,m):null)},O:function(a,b,c){r.texParameterf(a,b,c)},N:function(a,b,c){r.texParameterf(a,b,F[c>>2])},M:function(a,b,c){r.texParameteri(a,b,c)},L:function(a,b,c){r.texParameteri(a,b,C[c>>2])},Zb:function(a,
b,c,d,e){r.texStorage2D(a,b,c,d,e)},J:function(a,b,c,d,e,f,g,h,m){if(2<=l.version)if(r.ud)r.texSubImage2D(a,b,c,d,e,f,g,h,m);else if(m){var p=Ec(h);r.texSubImage2D(a,b,c,d,e,f,g,h,p,m>>31-Math.clz32(p.BYTES_PER_ELEMENT))}else r.texSubImage2D(a,b,c,d,e,f,g,h,null);else p=null,m&&(p=Fc(h,g,e,f,m)),r.texSubImage2D(a,b,c,d,e,f,g,h,p)},I:function(a,b){r.uniform1f(V(a),b)},H:function(a,b,c){if(2<=l.version)r.uniform1fv(V(a),F,c>>2,b);else{if(288>=b)for(var d=Gc[b-1],e=0;e<b;++e)d[e]=F[c+4*e>>2];else d=
F.subarray(c>>2,c+4*b>>2);r.uniform1fv(V(a),d)}},G:function(a,b){r.uniform1i(V(a),b)},F:function(a,b,c){if(2<=l.version)r.uniform1iv(V(a),C,c>>2,b);else{if(288>=b)for(var d=Hc[b-1],e=0;e<b;++e)d[e]=C[c+4*e>>2];else d=C.subarray(c>>2,c+4*b>>2);r.uniform1iv(V(a),d)}},E:function(a,b,c){r.uniform2f(V(a),b,c)},D:function(a,b,c){if(2<=l.version)r.uniform2fv(V(a),F,c>>2,2*b);else{if(144>=b)for(var d=Gc[2*b-1],e=0;e<2*b;e+=2)d[e]=F[c+4*e>>2],d[e+1]=F[c+(4*e+4)>>2];else d=F.subarray(c>>2,c+8*b>>2);r.uniform2fv(V(a),
d)}},C:function(a,b,c){r.uniform2i(V(a),b,c)},B:function(a,b,c){if(2<=l.version)r.uniform2iv(V(a),C,c>>2,2*b);else{if(144>=b)for(var d=Hc[2*b-1],e=0;e<2*b;e+=2)d[e]=C[c+4*e>>2],d[e+1]=C[c+(4*e+4)>>2];else d=C.subarray(c>>2,c+8*b>>2);r.uniform2iv(V(a),d)}},A:function(a,b,c,d){r.uniform3f(V(a),b,c,d)},Ec:function(a,b,c){if(2<=l.version)r.uniform3fv(V(a),F,c>>2,3*b);else{if(96>=b)for(var d=Gc[3*b-1],e=0;e<3*b;e+=3)d[e]=F[c+4*e>>2],d[e+1]=F[c+(4*e+4)>>2],d[e+2]=F[c+(4*e+8)>>2];else d=F.subarray(c>>2,
c+12*b>>2);r.uniform3fv(V(a),d)}},Dc:function(a,b,c,d){r.uniform3i(V(a),b,c,d)},Cc:function(a,b,c){if(2<=l.version)r.uniform3iv(V(a),C,c>>2,3*b);else{if(96>=b)for(var d=Hc[3*b-1],e=0;e<3*b;e+=3)d[e]=C[c+4*e>>2],d[e+1]=C[c+(4*e+4)>>2],d[e+2]=C[c+(4*e+8)>>2];else d=C.subarray(c>>2,c+12*b>>2);r.uniform3iv(V(a),d)}},Bc:function(a,b,c,d,e){r.uniform4f(V(a),b,c,d,e)},Ac:function(a,b,c){if(2<=l.version)r.uniform4fv(V(a),F,c>>2,4*b);else{if(72>=b){var d=Gc[4*b-1],e=F;c>>=2;for(var f=0;f<4*b;f+=4){var g=c+
f;d[f]=e[g];d[f+1]=e[g+1];d[f+2]=e[g+2];d[f+3]=e[g+3]}}else d=F.subarray(c>>2,c+16*b>>2);r.uniform4fv(V(a),d)}},zc:function(a,b,c,d,e){r.uniform4i(V(a),b,c,d,e)},yc:function(a,b,c){if(2<=l.version)r.uniform4iv(V(a),C,c>>2,4*b);else{if(72>=b)for(var d=Hc[4*b-1],e=0;e<4*b;e+=4)d[e]=C[c+4*e>>2],d[e+1]=C[c+(4*e+4)>>2],d[e+2]=C[c+(4*e+8)>>2],d[e+3]=C[c+(4*e+12)>>2];else d=C.subarray(c>>2,c+16*b>>2);r.uniform4iv(V(a),d)}},xc:function(a,b,c,d){if(2<=l.version)r.uniformMatrix2fv(V(a),!!c,F,d>>2,4*b);else{if(72>=
b)for(var e=Gc[4*b-1],f=0;f<4*b;f+=4)e[f]=F[d+4*f>>2],e[f+1]=F[d+(4*f+4)>>2],e[f+2]=F[d+(4*f+8)>>2],e[f+3]=F[d+(4*f+12)>>2];else e=F.subarray(d>>2,d+16*b>>2);r.uniformMatrix2fv(V(a),!!c,e)}},wc:function(a,b,c,d){if(2<=l.version)r.uniformMatrix3fv(V(a),!!c,F,d>>2,9*b);else{if(32>=b)for(var e=Gc[9*b-1],f=0;f<9*b;f+=9)e[f]=F[d+4*f>>2],e[f+1]=F[d+(4*f+4)>>2],e[f+2]=F[d+(4*f+8)>>2],e[f+3]=F[d+(4*f+12)>>2],e[f+4]=F[d+(4*f+16)>>2],e[f+5]=F[d+(4*f+20)>>2],e[f+6]=F[d+(4*f+24)>>2],e[f+7]=F[d+(4*f+28)>>2],e[f+
8]=F[d+(4*f+32)>>2];else e=F.subarray(d>>2,d+36*b>>2);r.uniformMatrix3fv(V(a),!!c,e)}},vc:function(a,b,c,d){if(2<=l.version)r.uniformMatrix4fv(V(a),!!c,F,d>>2,16*b);else{if(18>=b){var e=Gc[16*b-1],f=F;d>>=2;for(var g=0;g<16*b;g+=16){var h=d+g;e[g]=f[h];e[g+1]=f[h+1];e[g+2]=f[h+2];e[g+3]=f[h+3];e[g+4]=f[h+4];e[g+5]=f[h+5];e[g+6]=f[h+6];e[g+7]=f[h+7];e[g+8]=f[h+8];e[g+9]=f[h+9];e[g+10]=f[h+10];e[g+11]=f[h+11];e[g+12]=f[h+12];e[g+13]=f[h+13];e[g+14]=f[h+14];e[g+15]=f[h+15]}}else e=F.subarray(d>>2,d+
64*b>>2);r.uniformMatrix4fv(V(a),!!c,e)}},tc:function(a){a=R[a];r.useProgram(a);r.ge=a},sc:function(a,b){r.vertexAttrib1f(a,b)},rc:function(a,b){r.vertexAttrib2f(a,F[b>>2],F[b+4>>2])},qc:function(a,b){r.vertexAttrib3f(a,F[b>>2],F[b+4>>2],F[b+8>>2])},pc:function(a,b){r.vertexAttrib4f(a,F[b>>2],F[b+4>>2],F[b+8>>2],F[b+12>>2])},Yb:function(a,b){r.vertexAttribDivisor(a,b)},Xb:function(a,b,c,d,e){r.vertexAttribIPointer(a,b,c,d,e)},oc:function(a,b,c,d,e,f){r.vertexAttribPointer(a,b,c,!!d,e,f)},nc:function(a,
b,c,d){r.viewport(a,b,c,d)},$a:function(a,b,c,d){r.waitSync(sc[a],b,(c>>>0)+4294967296*d)},t:function(a){var b=z.length;a>>>=0;if(2147483648<a)return!1;for(var c=1;4>=c;c*=2){var d=b*(1+.2/c);d=Math.min(d,a+100663296);d=Math.max(a,d);0<d%65536&&(d+=65536-d%65536);a:{try{ua.grow(Math.min(2147483648,d)-Ja.byteLength+65535>>>16);Na();var e=1;break a}catch(f){}e=void 0}if(e)return!0}return!1},qb:function(a,b){var c=0;Jc().forEach(function(d,e){var f=b+c;e=C[a+4*e>>2]=f;for(f=0;f<d.length;++f)Ka[e++>>
0]=d.charCodeAt(f);Ka[e>>0]=0;c+=d.length+1});return 0},rb:function(a,b){var c=Jc();C[a>>2]=c.length;var d=0;c.forEach(function(e){d+=e.length+1});C[b>>2]=d;return 0},tb:function(){return 0},eb:function(){},sb:function(a,b,c,d){for(var e=0,f=0;f<c;f++){var g=C[b>>2],h=C[b+4>>2];b+=8;for(var m=0;m<h;m++){var p=z[g+m],q=Lc[a];0===p||10===p?((1===a?ra:x)(xa(q,0)),q.length=0):q.push(p)}e+=h}C[d>>2]=e;return 0},b:function(){return sa},db:function(a,b){r.bindFramebuffer(a,nc[b])},w:function(a,b){Ac(a,b)},
n:Yc,g:Zc,o:$c,r:ad,hb:bd,ib:cd,q:dd,j:ed,d:fd,p:gd,i:hd,kb:jd,gb:kd,lb:ld,c:function(a){sa=a},pb:function(a,b,c,d){return Rc(a,b,c,d)}};
(function(){function a(e){k.asm=e.exports;ua=k.asm.Hc;Na();Oa=k.asm.Rc;Qa.unshift(k.asm.Ic);Ta--;k.monitorRunDependencies&&k.monitorRunDependencies(Ta);0==Ta&&(null!==Ua&&(clearInterval(Ua),Ua=null),Va&&(e=Va,Va=null,e()))}function b(e){a(e.instance)}function c(e){return Za().then(function(f){return WebAssembly.instantiate(f,d)}).then(function(f){return f}).then(e,function(f){x("failed to asynchronously prepare wasm: "+f);qa(f)})}var d={a:md};Ta++;k.monitorRunDependencies&&k.monitorRunDependencies(Ta);
if(k.instantiateWasm)try{return k.instantiateWasm(d,a)}catch(e){return x("Module.instantiateWasm callback failed with error: "+e),!1}(function(){return ta||"function"!==typeof WebAssembly.instantiateStreaming||Wa()||G.startsWith("file://")||"function"!==typeof fetch?c(b):fetch(G,{credentials:"same-origin"}).then(function(e){return WebAssembly.instantiateStreaming(e,d).then(b,function(f){x("wasm streaming compile failed: "+f);x("falling back to ArrayBuffer instantiation");return c(b)})})})().catch(ba);
return{}})();k.___wasm_call_ctors=function(){return(k.___wasm_call_ctors=k.asm.Ic).apply(null,arguments)};var Cc=k._malloc=function(){return(Cc=k._malloc=k.asm.Jc).apply(null,arguments)},O=k._free=function(){return(O=k._free=k.asm.Kc).apply(null,arguments)},Xc=k.___errno_location=function(){return(Xc=k.___errno_location=k.asm.Lc).apply(null,arguments)},Pb=k.___getTypeName=function(){return(Pb=k.___getTypeName=k.asm.Mc).apply(null,arguments)};
k.___embind_register_native_and_builtin_types=function(){return(k.___embind_register_native_and_builtin_types=k.asm.Nc).apply(null,arguments)};var X=k.stackSave=function(){return(X=k.stackSave=k.asm.Oc).apply(null,arguments)},Y=k.stackRestore=function(){return(Y=k.stackRestore=k.asm.Pc).apply(null,arguments)},Z=k._setThrew=function(){return(Z=k._setThrew=k.asm.Qc).apply(null,arguments)};k.dynCall_iiiij=function(){return(k.dynCall_iiiij=k.asm.Sc).apply(null,arguments)};
k.dynCall_viiij=function(){return(k.dynCall_viiij=k.asm.Tc).apply(null,arguments)};k.dynCall_iiij=function(){return(k.dynCall_iiij=k.asm.Uc).apply(null,arguments)};k.dynCall_viij=function(){return(k.dynCall_viij=k.asm.Vc).apply(null,arguments)};k.dynCall_viiiiij=function(){return(k.dynCall_viiiiij=k.asm.Wc).apply(null,arguments)};k.dynCall_jii=function(){return(k.dynCall_jii=k.asm.Xc).apply(null,arguments)};k.dynCall_viji=function(){return(k.dynCall_viji=k.asm.Yc).apply(null,arguments)};
k.dynCall_ji=function(){return(k.dynCall_ji=k.asm.Zc).apply(null,arguments)};k.dynCall_iij=function(){return(k.dynCall_iij=k.asm._c).apply(null,arguments)};k.dynCall_vij=function(){return(k.dynCall_vij=k.asm.$c).apply(null,arguments)};k.dynCall_jiji=function(){return(k.dynCall_jiji=k.asm.ad).apply(null,arguments)};k.dynCall_viijii=function(){return(k.dynCall_viijii=k.asm.bd).apply(null,arguments)};k.dynCall_iiiiij=function(){return(k.dynCall_iiiiij=k.asm.cd).apply(null,arguments)};
k.dynCall_iiiiijj=function(){return(k.dynCall_iiiiijj=k.asm.dd).apply(null,arguments)};k.dynCall_iiiiiijj=function(){return(k.dynCall_iiiiiijj=k.asm.ed).apply(null,arguments)};function fd(a,b,c){var d=X();try{H(a)(b,c)}catch(e){Y(d);if(e!==e+0&&"longjmp"!==e)throw e;Z(1,0)}}function $c(a,b,c,d){var e=X();try{return H(a)(b,c,d)}catch(f){Y(e);if(f!==f+0&&"longjmp"!==f)throw f;Z(1,0)}}function hd(a,b,c,d,e){var f=X();try{H(a)(b,c,d,e)}catch(g){Y(f);if(g!==g+0&&"longjmp"!==g)throw g;Z(1,0)}}
function ed(a,b){var c=X();try{H(a)(b)}catch(d){Y(c);if(d!==d+0&&"longjmp"!==d)throw d;Z(1,0)}}function Zc(a,b,c){var d=X();try{return H(a)(b,c)}catch(e){Y(d);if(e!==e+0&&"longjmp"!==e)throw e;Z(1,0)}}function ad(a,b,c,d,e){var f=X();try{return H(a)(b,c,d,e)}catch(g){Y(f);if(g!==g+0&&"longjmp"!==g)throw g;Z(1,0)}}function Yc(a,b){var c=X();try{return H(a)(b)}catch(d){Y(c);if(d!==d+0&&"longjmp"!==d)throw d;Z(1,0)}}
function dd(a){var b=X();try{H(a)()}catch(c){Y(b);if(c!==c+0&&"longjmp"!==c)throw c;Z(1,0)}}function ld(a,b,c,d,e,f,g,h,m){var p=X();try{H(a)(b,c,d,e,f,g,h,m)}catch(q){Y(p);if(q!==q+0&&"longjmp"!==q)throw q;Z(1,0)}}function gd(a,b,c,d){var e=X();try{H(a)(b,c,d)}catch(f){Y(e);if(f!==f+0&&"longjmp"!==f)throw f;Z(1,0)}}function jd(a,b,c,d,e,f){var g=X();try{H(a)(b,c,d,e,f)}catch(h){Y(g);if(h!==h+0&&"longjmp"!==h)throw h;Z(1,0)}}
function cd(a,b,c,d,e,f,g){var h=X();try{return H(a)(b,c,d,e,f,g)}catch(m){Y(h);if(m!==m+0&&"longjmp"!==m)throw m;Z(1,0)}}function bd(a,b,c,d,e,f){var g=X();try{return H(a)(b,c,d,e,f)}catch(h){Y(g);if(h!==h+0&&"longjmp"!==h)throw h;Z(1,0)}}function kd(a,b,c,d,e,f,g){var h=X();try{H(a)(b,c,d,e,f,g)}catch(m){Y(h);if(m!==m+0&&"longjmp"!==m)throw m;Z(1,0)}}var nd;Va=function od(){nd||pd();nd||(Va=od)};
function pd(){function a(){if(!nd&&(nd=!0,k.calledRun=!0,!va)){$a(Qa);aa(k);if(k.onRuntimeInitialized)k.onRuntimeInitialized();if(k.postRun)for("function"==typeof k.postRun&&(k.postRun=[k.postRun]);k.postRun.length;){var b=k.postRun.shift();Ra.unshift(b)}$a(Ra)}}if(!(0<Ta)){if(k.preRun)for("function"==typeof k.preRun&&(k.preRun=[k.preRun]);k.preRun.length;)Sa();$a(Pa);0<Ta||(k.setStatus?(k.setStatus("Running..."),setTimeout(function(){setTimeout(function(){k.setStatus("")},1);a()},1)):a())}}
k.run=pd;if(k.preInit)for("function"==typeof k.preInit&&(k.preInit=[k.preInit]);0<k.preInit.length;)k.preInit.pop()();pd();


  return Rive.ready
}
);
})();
export default Rive;