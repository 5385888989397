import Button from "components/Button";
import logo from "images/logo-white.svg";
import arrowRight from "images/arrow-right.svg";
import { useEffect, useState } from "react";
import { PopupModal } from "react-calendly";
import { Link, useLocation } from "react-router-dom";
import Banner from "components/Banner";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleClick = () => {
      setShowModal(false);
    };
    const modal = document.querySelector(".calendly-overlay");

    modal?.addEventListener("click", handleClick);
    return () => {
      modal?.removeEventListener("click", handleClick);
    };
  }, [showModal]);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const year = new Date().getFullYear();
  if (location.pathname !== "/" && !location.pathname.includes("jobs")) {
    return null;
  }
  return (
    <div
      className="max-w-full pb-14 px-4 justify-center flex flex-col bg-text-50 items-center bg-darkBlue-900 text-white text-center"
      id="#footer"
    >
      <PopupModal
        url="https://calendly.com/damir-ivanic"
        onModalClose={handleClose}
        open={showModal}
        rootElement={document.getElementById("root")!}
        pageSettings={{
          hideGdprBanner: true,
          hideEventTypeDetails: true,
          hideLandingPageDetails: false,
        }}
      />
      <div>
        <div
          className="grid sm:grid-cols-2 mt-20 sm:mt-48"
          data-aos="fade-in"
          data-aos-delay="50"
        >
          <div className="flex flex-col mb-10 sm:mb-0">
            <img
              src={logo}
              alt="Deflty logo"
              className="h-10 mb-14 sm:self-start"
            />
            <div className="grid grid-flow-row justify-items-start gap-6">
              <div className="flex">
                <img
                  src={arrowRight}
                  alt="Arrow right"
                  className="text-dark-200 hover:text-white text-base mr-2"
                />
                <a
                  className="text-dark-200 hover:text-white text-base"
                  href="mailto:office@deftly.tech"
                >
                  office@deftly.tech
                </a>
              </div>
              <div className="flex">
                <img
                  src={arrowRight}
                  alt="Arrow right"
                  className="text-dark-200 hover:text-white text-base mr-2"
                />
                <a
                  className="text-dark-200 hover:text-white text-base"
                  href="https://www.linkedin.com/company/deftly-tech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Linkedin
                </a>
              </div>
              <div className="flex">
                <img
                  src={arrowRight}
                  alt="Arrow right"
                  className="text-dark-200 hover:text-white text-base mr-2"
                />
                <a
                  className="text-dark-200 hover:text-white text-base"
                  href="https://instagram.com/deftly.tech"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center bg-dark-700 flex-col p-16 rounded-2xl">
            <h1 className="w-auto sm:text-left">Have something to discuss?</h1>
            <div className="flex justify-center items-center">
              <Button
                className="self-center sm:self-start sm:mb-0 mt-6"
                onClick={handleOpen}
              >
                Schedule a call
              </Button>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full mt-24">
          <span className="text-dark-300">{`© Deftly Tech ${year}`}</span>
          <Link to="/terms-and-conditions" className="text-dark-300">Privacy policy and terms & conditions</Link>
          <Banner />
        </div>
      </div>
    </div>
  );
};

export default Footer;
