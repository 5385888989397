import { X } from "lucide-react";
import { useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";


const TermsAndConditions = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0 });
    }, [location.pathname]);

    return (
        <body className="bg-gray-100 font-sans leading-normal tracking-normal">
            <button
                onClick={() => navigate(-1)}
                className="fixed top-4 right-4 p-2 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50 transition-colors duration-200"
                aria-label="Close"
            >
                <X size={24} />
            </button>
            <div className="container mx-auto p-8">
                <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Terms & Conditions</h1>

                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">1. Acceptance of Terms</h2>
                    <p className="mb-4">By accessing and using this website deftly.tech, you agree to be bound by these Terms & Conditions. If you do not agree to these terms, please do not use the Site.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">2. Use of the Site</h2>
                    <p className="mb-4">You agree to use the Site only for lawful purposes and in a manner that does not infringe on the rights of, restrict, or inhibit anyone else's use and enjoyment of the Site. Prohibited behavior includes, but is not limited to, harassment, disruption of the Site, or any illegal activities.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">3. Intellectual Property</h2>
                    <p className="mb-4">All content on the Site, including but not limited to text, graphics, logos, and images, is the property of the Site owner or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. Unauthorized use of this content is prohibited.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">4. Disclaimer of Warranties</h2>
                    <p className="mb-4">The Site is provided on an "as is" and "as available" basis. The Site owner makes no warranties or representations about the accuracy, reliability, or completeness of the Site's content. Your use of the Site is at your own risk.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">5. Limitation of Liability</h2>
                    <p className="mb-4">To the maximum extent permitted by law, the Site owner will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, damages for loss of profits, goodwill, or other intangible losses, arising out of or in connection with your use of the Site.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">6. Changes to Terms</h2>
                    <p className="mb-4">The Site owner reserves the right to modify these Terms & Conditions at any time. Any changes will be effective immediately upon posting on the Site. Your continued use of the Site after any changes constitutes your acceptance of the new Terms & Conditions.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">7. Governing Law</h2>
                    <p className="mb-4">These Terms & Conditions are governed by and construed in accordance with the laws of the Republic of Serbia. Any disputes arising under or in connection with these Terms & Conditions will be subject to the exclusive jurisdiction of the courts in the Republic of Serbia.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">8. Contact Information</h2>
                    <p className="mb-4">For any questions about these Terms & Conditions, please contact us at office@deftly.tech.</p>
                </div>

                <h1 className="text-4xl font-bold my-8 text-center text-gray-800">Privacy Policy</h1>

                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">1. Introduction</h2>
                    <p className="mb-4">We are committed to protecting your privacy. This Privacy Policy explains how we handle any information you provide while using our website (the "https://deftly.tech/").</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">2. Information Collection</h2>
                    <p className="mb-4">We do not collect any personal data from visitors to the Site. We also do not use cookies or any other tracking technologies.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">3. Use of Information</h2>
                    <p className="mb-4">Since we do not collect personal data or use cookies, we do not use, share, or store any information about you.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">4. Third-Party Links</h2>
                    <p className="mb-4">The Site may contain links to third-party websites. We are not responsible for the privacy practices or content of those third-party sites. Please review their privacy policies before providing any personal information.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">5. Data Security</h2>
                    <p className="mb-4">While we do not collect personal data, we take reasonable measures to protect the Site from unauthorized access or disclosure.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">6. Changes to Privacy Policy</h2>
                    <p className="mb-4">We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the Site constitutes your acceptance of any changes.</p>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">7. Contact Information</h2>
                    <p className="mb-4">If you have any questions or concerns about this Privacy Policy, please contact us at office@deftly.tech.</p>
                </div>
            </div>
        </body>
    )
}

export default TermsAndConditions