import {
  useRef,
  useLayoutEffect,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";
import contrastLogo from "images/contrastLogo.svg";
import closeIcon from "images/close_icon.svg";

type Props = {
  openMenu: boolean;
  handleToggleMenu: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
};

const MobileMenu = ({ openMenu, handleToggleMenu, children }: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    function handleClickOutside(event: Event) {
      const target = event.target as HTMLElement;

      if (
        menuRef.current &&
        !menuRef.current.contains(target) &&
        target.id !== "hamburger"
      ) {
        handleToggleMenu(true);
      }
    }
    if (openMenu) {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [menuRef, handleToggleMenu, openMenu]);

  return (
    <div
      ref={menuRef}
      className="absolute p-5 top-0 h-screen flex flex-col items-start bg-white w-full z-50 left-0 overflow-hidden"
      style={{
        transformOrigin: "0% 0%",
        transform: openMenu ? "none" : "translate(-100%, 0)",
        transition: "transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0)",
      }}
    >
      <div
        className={`grid grid-cols-1 gap-2 w-full max-h-[calc(100%-165px)] overflow-scroll pb-10`}
      >
        <div className="flex justify-between items-center">
          <img
            src={contrastLogo}
            alt="logo of Deftly"
            className={`flex-shrink-0 cursor-pointer h-10`}
          />
          <img
            onClick={() => handleToggleMenu(false)}
            src={closeIcon}
            alt="logo of Deftly"
            className={`flex-shrink-0 cursor-pointer h-8 p-2`}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default MobileMenu;
