import React from 'react';
import JobLayout from './JobLayout';

const SeniorDotNet = () => {
    return (
        <JobLayout title="Senior Backend (.Net) Engineer">
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg my-10">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">
                    We are on the lookout for an inspiring Senior Backend (.Net) Engineer to join our offices in Serbia!
                </h1>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-gray-700 mb-2">About Deftly</h2>
                    <p className="text-gray-600">
                        Deftly is a group of experienced digital product providers with vast experience in many technologies. We are working with both well-established brands and exciting and promising startups. Founded in 2020, Deftly started as a small company of friends that grew over time into multiple teams of experts that help well-established brands transition their legacy systems to new and scalable solutions, and startups to reach their full potential.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-gray-700 mb-2">What would be your job, you ask?</h2>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                        <li>Designing, developing, and maintaining backend services, APIs, and databases using .NET Core/Framework to support various client applications.</li>
                        <li>Architecting and implementing scalable, secure, and high-performance systems.</li>
                        <li>Collaborating with other software and test automation engineers, designers, DevOps, and product managers to implement new features and improve existing ones.</li>
                        <li>Integrating with third-party services like Auth0 for authentication and authorization.</li>
                        <li>Troubleshooting and optimizing cloud infrastructure (AWS) in collaboration with the DevOps engineers to ensure performance, scalability, and security.</li>
                        <li>Writing clean, maintainable, and efficient code, following industry best practices.</li>
                        <li>Troubleshooting and optimizing existing backend systems.</li>
                    </ul>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-gray-700 mb-2">What we are looking for:</h2>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                        <li>5+ years of experience in Full Stack development with a focus on .NET technologies.</li>
                        <li>Strong knowledge of C#, .NET Core/Framework, ASP.NET, and MVC.</li>
                        <li>In-depth knowledge of RESTful API design.</li>
                        <li>Experience with relational databases (e.g., Postgres Server).</li>
                        <li>Experience with cloud platforms (Azure or AWS) and containerization (Docker, Kubernetes).</li>
                        <li>Experience with GIT and GitHub Actions.</li>
                        <li>Experience with microservices architecture is a plus.</li>
                        <li>Excellent knowledge of the English language (Fluent and written).</li>
                        <li>Great communication skills and time management.</li>
                        <li>Bachelor's degree in computer science, engineering, or related field is a plus.</li>
                    </ul>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-gray-700 mb-2">We offer:</h2>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                        <li>A full-time indefinite contract.</li>
                        <li>Hybrid model of work (you decide if you want to work from our offices, home, or someplace else) and flexible working hours.</li>
                        <li>23 days of paid vacation per year.</li>
                        <li>10 days of paid sick leave without a doctor’s note.</li>
                        <li>Private health insurance.</li>
                        <li>Paid conferences and courses.</li>
                        <li>Occasional business or teambuilding trips.</li>
                    </ul>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-gray-700 mb-2">Want to build something deftly with us?</h2>
                    {/* <p className="text-gray-600 mb-4">
                        Apply through <a href="https://www.linkedin.com/company/74848376" className="text-blue-500 hover:underline">LinkedIn</a> or by sending your resume!
                    </p> */}
                    <p className="text-gray-600">
                        If you need more information, feel free to contact us at <a href="mailto:jobs@deftly.tech" className="text-blue-500 hover:underline">jobs@deftly.tech</a>
                    </p>
                </section>
            </div>
        </JobLayout>
    );
};

export default SeniorDotNet;
