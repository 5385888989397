// import { useBlogs } from "api/blog";
import PageTitle from "components/PageTitle";

const BlogSection = () => {
  // const { blogs } = useBlogs();

  return (
    <section
      className="py-36 px-2 sm:px-10 justify-center flex flex-col bg-text-50 items-center"
      id="#blog"
    >
      <div data-aos="fade-in" className="w-full max-w-6xl text-purple-800">
        <PageTitle
          title="Blog"
          styleClass="text-purple-400"
          dashClass="bg-purple-400"
        />
        <h2 className="text-center sm:text-left">
          Believe it or not, but we also write in plain language.
        </h2>
        {/* {blogs && blogs?.length > 0 ? (
          <div className="flex mt-8">
            {blogs.map((blog) => {
              return (
                // <BlogBox
                //   title={blog.attributes.Title}
                //   image={blog?.attributes.Image}
                //   key={blog.id}
                // />
                <div></div>
              );
            })}
          </div>
        ) : ( */}
        <h5 className="text-center sm:text-left mt-5">
          We are currently building some amazing projects for our clients. Check
          back later for updates on how we do it.
        </h5>
        {/* )} */}
      </div>
    </section>
  );
};

export default BlogSection;
