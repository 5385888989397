import PageNotFound from "components/PageNotFound";
import LandingPage from "containers/LandingPage";
import TermsAndConditions from "containers/TermsAndConditions";
import MediorDotNet from "jobs/MediorDotNet";
import SeniorDotNet from "jobs/SeniorDotNet";
import { useRoutes } from "react-router-dom";

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/jobs/senior-dot-net-developer",
      element: <SeniorDotNet />,
    },
    {
      path: "/jobs/medior-dot-net-developer",
      element: <MediorDotNet />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsAndConditions />,
    },
    { path: "*", element: <PageNotFound /> },
  ]);
}
