import { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: () => void;
  className?: string;
};

const Button: FC<Props> = ({ children, onClick, className }: Props) => {
  return (
    <div
      onClick={onClick}
      className={`bg-blue-500 text-blue-900 hover:bg-blue-600 rounded-[50px] w-56 h-14 flex items-center justify-center cursor-pointer ${className}`}
    >
      <h5 className="font-semi-bold">{children}</h5>
    </div>
  );
};

export default Button;
