import Rive from "rive-react";
import Button from "components/Button";
import Services from "./Services";
import About from "./About";
import Positions from "./Positions";
import BlogSection from "./BlogSection";

const LandingPage = () => {
  // const Blog = useFetchDoc("blog", "PzlAI5Y0V26hGvOVpB4B");

  const handleClick = () => {
    document?.getElementById("#services")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className="h-full overflow-auto overflow-x-hidden">
      <section
        className="py-36 px-2 sm:px-10 justify-center flex bg-dark-900 items-center text-[#F4F6F6] flex-row rounded-b-xl sm:rounded-b-[40px]"
        id="#cta"
      >
        <div className="w-full max-w-6xl flex flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-1/2 md:items-start justify-start md:mr-5 items-center">
            <h1 className="text-6xl md:text-left text-center">
              Experienced in digital product development
            </h1>
            <h5 className="mt-7 mb-14 text-dark-200 text-xl">
              We get partnered with companies in need of expert software
              services.
            </h5>
            <Button className="md:mb-0 mb-4" onClick={handleClick}>
              Learn more
            </Button>
          </div>
          <Rive className="h-96 w-full md:w-1/2" src="dots.riv"></Rive>
        </div>
      </section>
      <Services />
      <About />
      <BlogSection />
      <Positions />
    </div>
  );
};

export default LandingPage;
