type Props = {
  title: string;
  styleClass?: string;
  dashClass?: string;
};

const PageTitle = ({ title, styleClass, dashClass }: Props) => {
  return (
    <div
      className={`${
        styleClass ? styleClass : "text-blue-400"
      } flex items-center mb-6`}
    >
      <div
        className={`${
          dashClass ? dashClass : "bg-blue-400"
        } w-9 h-0.5 mr-5 hidden sm:block`}
      />

      <p className="text-center sm:text-left uppercase w-full sm:w-auto font-inter font-semibold">
        {title}
      </p>
    </div>
  );
};

export default PageTitle;
