import advania from "images/adv.svg";
import brightFlag from "images/bf.svg";
import sastrify from "images/sastrify.svg";
import dte from "images/dte.svg";
import AboutUsBox from "components/AboutUsBox";
import PageTitle from "components/PageTitle";

const About = () => {
  return (
    <section
      className="py-36 px-4 sm:px-10 justify-center flex flex-col bg-darkBlue-900 items-center rounded-xl sm:rounded-[40px]"
      id="#about"
    >
      <div
        data-aos="fade-in"
        data-aos-delay="50"
        className="w-full max-w-6xl text-white"
      >
        <PageTitle
          title="About us"
          dashClass="bg-darkBlue-400"
          styleClass="text-darkBlue-400"
        />
        <h2>
          We collaborate with business in finding their needs and creating
          solutions. Doing that with great knowledge and persistence.
        </h2>
        <div className="text-center mt-28">
          <h2>Oh, how we operate?</h2>
          <div className="mt-3 text-darkBlue-200">
            We offer several options for cooperation.
          </div>
        </div>
        <div className="flex sm:flex-row flex-col mt-14">
          <AboutUsBox
            riveIcon="service.riv"
            title="Team as a service"
            description="With this approach, you’ll get a chance to collaborate with our high-spirited group of specialists who’ll help bring your product to life. "
          />
          <AboutUsBox
            riveIcon="augmented.riv"
            title="Augmented teams"
            description="Need a specific service? You don’t have to spend hours on Google trying to find the right person. We got you covered!"
          />
          <AboutUsBox
            riveIcon="individual.riv"
            title="Individual Consultants"
            description="If you get stuck at any stage of the development process, hire one of our individual consultants to help get you back on track."
          />
        </div>
        <div className="flex sm:flex-row flex-col justify-between border-t border-darkBlue-700 pt-16 mt-16">
          <h4 className="flex items-center justify-center sm:justify-start w-full sm:w-auto mb-10 sm:mb-0">
            Partnered with the best:
          </h4>
          <div className="grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-10 lg:gap-20 justify-around">
            <a
              rel="noreferrer"
              className="sm:opacity-50 hover:opacity-100"
              href="https://www.advania.is/"
              target="_blank"
            >
              <img src={advania} alt="advania-logo" className="h-10 flex" />
            </a>
            <a
              rel="noreferrer"
              className="sm:opacity-50 hover:opacity-100"
              href="https://www.sastrify.com/"
              target="_blank"
            >
              <img
                src={sastrify}
                alt="bright-flag-logo"
                className="h-10 flex"
              />
            </a>
            <a
              rel="noreferrer"
              className="sm:opacity-50 hover:opacity-100"
              href="https://www.dte.ai/"
              target="_blank"
            >
              <img src={dte} alt="dte-logo" className="h-10 flex" />
            </a>
            <a
              rel="noreferrer"
              className="sm:opacity-50 hover:opacity-100"
              href="https://brightflag.com/"
              target="_blank"
            >
              <img
                src={brightFlag}
                alt="bright-marbles-logo"
                className="h-10 flex"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
