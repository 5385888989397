import { X } from 'lucide-react';
import { useState } from 'react'

const Banner = () => {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) return null;

    return (
        <div className="fixed bottom-4 right-4 left-4 md:left-auto md:w-96 w-full bg-[#009F6E] text-white p-4 rounded-lg shadow-lg flex justify-center sm:justify-between items-center">
            <div>
                <p className="font-semibold">
                    We're working on an exciting cloud deployment solution!
                </p>
                <a
                    href="https://deftly.cloud"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline hover:text-blue-200 transition-colors"
                >
                    Check out deftly.cloud
                </a>
            </div>
            <button
                onClick={() => setIsVisible(false)}
                className="p-1 hover:bg-[#00805B] rounded-full transition-colors"
                aria-label="Dismiss"
            >
                <X size={20} />
            </button>
        </div>
    );
}

export default Banner