import logo from "images/logo.svg";
import contrastLogo from "images/contrastLogo.svg";
import { useEffect, useState } from "react";
import MobileMenu from "components/MobileMenu";
import { useLocation } from "react-router-dom";
import { SquareArrowOutUpRight } from "lucide-react";

const Header = () => {
  const [offset, setOffset] = useState(false);
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = useState(false);
  const style =
    "xl:ml-14 hover:bg-dark-700  cursor-pointer rounded-[50px] px-5 py-4 xl:bg-transparent hover:text-white";

  const handleClick = (hash: string) => {
    document?.getElementById(hash)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setOffset(window.pageYOffset > 108);
      });
    }
  }, []);

  if (location.pathname !== "/") {
    return null;
  }

  return (
    <header
      className={`${offset
        ? "bg-white ease-in duration-300 border-b border-dark-100 rounded-b rouded-xl"
        : "bg-dark-900 ease-in duration-500"
        } py-5 px-4 xl:px-10 flex justify-between sticky top-0 z-20`}
    >
      <a href="/" className="flex-shrink-0">
        <img
          src={offset ? contrastLogo : logo}
          alt="logo of Deftly"
          className={`flex-shrink-0 cursor-pointer h-10`}
        />
      </a>

      <div
        onClick={() => setMobileOpen(true)}
        className={`font-semibold flex xl:hidden items-center ${!offset && "text-white"
          } cursor-pointer z-50`}
      >
        Jump to
      </div>

      <MobileMenu openMenu={mobileOpen} handleToggleMenu={setMobileOpen}>
        <ul className="flex flex-col mt-2">
          <li
            tabIndex={1}
            className={`${style} "bg-blue-900"}`}
            onClick={() => {
              setMobileOpen(false);
              handleClick("#cta");
            }}
          >
            Home
          </li>
          <li
            tabIndex={2}
            className={`${style} "bg-blue-900"}`}
            onClick={() => {
              setMobileOpen(false);
              handleClick("#services");
            }}
          >
            About
          </li>
          <li
            tabIndex={3}
            className={`${style} "bg-blue-900"}`}
            onClick={() => {
              setMobileOpen(false);
              handleClick("#blog");
            }}
          >
            Blog
          </li>
          <li
            tabIndex={4}
            className={`${style} "bg-blue-900"}`}
            onClick={() => {
              setMobileOpen(false);
              handleClick("#positions");
            }}
          >
            Careers
          </li>
          <li
            tabIndex={5}
            className={`${style} "bg-blue-900"} flex gap-2 items-center`}
            onClick={() => window.open("https://deftly.cloud")}
          >
            Deftly cloud
            <SquareArrowOutUpRight width={16} />
          </li>
          <li
            tabIndex={6}
            className={`${style} "bg-blue-900"}`}
            onClick={() => {
              setMobileOpen(false);
              handleClick("#footer");
            }}
          >
            Get in touch
          </li>
        </ul>
      </MobileMenu>

      <nav
        className={`items-center text-white absolute xl:static w-full xl:w-auto -ml-4 xl:ml-0`}
      >
        <ul
          className={`hidden xl:flex  flex-row  text-lg font-semibold justify-center items-center xl:static relative w-full xl:w-auto top-16 ${offset ? "text-dark-900" : "text-white"
            }`}
        >
          <li
            tabIndex={1}
            className={`${style} "bg-blue-900"}`}
            onClick={() => handleClick("#cta")}
          >
            Home
          </li>
          <li
            tabIndex={2}
            className={`${style} "bg-blue-900"}`}
            onClick={() => handleClick("#services")}
          >
            About
          </li>
          <li
            tabIndex={3}
            className={`${style} "bg-blue-900"}`}
            onClick={() => handleClick("#blog")}
          >
            Blog
          </li>
          <li
            tabIndex={4}
            className={`${style} "bg-blue-900"}`}
            onClick={() => handleClick("#positions")}
          >
            Careers
          </li>
          <li
            tabIndex={5}
            className={`${style} "bg-blue-900 flex items-center"}`}
            onClick={() => window.open("https://deftly.cloud")}
          >
            <span className="flex items-center">
              Deftly cloud
            </span>
            <SquareArrowOutUpRight className="ml-2" width={16} />
          </li>
          <li
            tabIndex={6}
            className={`xl:ml-14 hover:bg-dark-100  cursor-pointer rounded-[50px] px-5 py-4 bg-white text-dark-700 ${offset && "bg-blue-500"
              }`}
            onClick={() => handleClick("#footer")}
          >
            Get in touch
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
