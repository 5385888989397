import Rive from "rive-react";

type Props = {
  riveIcon: string;
  title: string;
  description: string;
};

const AboutUsBox = ({ riveIcon, description, title }: Props) => {
  return (
    <div className="bg-darkBlue-700 sm:bg-darkBlue-900 p-6 rounded-2xl text-center sm:my-0 my-5 hover:bg-darkBlue-800 cursor-pointer">
      <Rive src={riveIcon} className="sm:w-24 sm:h-24 w-20 h-20 m-auto" />
      <h4 className="mb-4 mt-10">{title}</h4>
      <p>{description}</p>
    </div>
  );
};

export default AboutUsBox;
