import PageTitle from "components/PageTitle";
import ServiceBox from "components/ServiceBox";
import Rive from "rive-react";

const Services = () => {
  return (
    <section
      className="py-36 px-2 sm:px-10 justify-center flex flex-col bg-text-50 items-center"
      id="#services"
    >
      <div className="w-full max-w-6xl">
        <PageTitle
          title="Our services"
          dashClass="bg-dark-400"
          styleClass="text-dark-400"
        />
        <h2 className="max-w-lg">
          How we take your product to the next level?
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5 mt-24">
          <ServiceBox
            number="01"
            title="Frontend development"
            description="We create eye-catching visual effects and 
            modern features for your websites and mobile applications.
            We make sure your design is top-notch and user-friendly.
            "
          />
          <ServiceBox
            number="02"
            title="Backend development"
            description="To keep your website working smoothly, we also 
            deal with the dirty work such as data processing and storage.
            Maybe it’s invisible to the user, but it’s still important, trust us.
            "
          />
          <ServiceBox
            number="03"
            title="Devops infrastructure"
            description="If you want to serve your customers more efficiently,
            we’ll employ our best practices and magical tools to 
            build your applications faster.
            "
          />
          <ServiceBox
            number="04"
            title="Testing & Automation"
            description="Bugs, we all hate ’em. To ensure no pesky bugs slow down your product, we’ll perform several types of tests and examine the result to check whether the software quality could be improved.
            "
          />
          <ServiceBox
            number="05"
            title="Design"
            description="Our UX and UI designers
            will give your website a polished and trendy look that your users will
            navigate with ease.
            "
          />
        </div>
        <Rive className="mt-14 w-full hidden md:flex" src="stack.riv" />
      </div>
    </section>
  );
};

export default Services;
